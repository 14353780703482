.contact_header {
  width: 100vw;
  padding: 200px 0;
  background-color: #FFD700;
  text-align: center;
}

.contact_header > h1 {
  font-size: 70px;
  color: #222222;
}

.contact_header > h2 {
  font-size: 20px;
  color: #222222;
}

.contact_content {
  width: 900px;
  padding: 100px 0 130px;
  border: 1px solid #222222;
  margin: -100px auto 150px;
  background-color: white;
}

@media (max-width: 950px) and (min-width: 501px) {
  .contact_content {
    width: 500px;
  }
}

@media (max-width: 500px) and (min-width: 0) {
  .contact_content {
    width: 90vw;
    border: none;
    margin: 50px auto 50px;
  }
}

.contact_content_progress {
  width: 310px;
  margin: 0 auto 100px;
}

.contact_content_progress > div {
  text-align: center;
  width: 310px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.contact_content_progress > div > p {
  font-size: 13px;
  color: #373737;
  margin: 0 8px;
}

.contact_content_progress > img {
  width: 300px;
  height: 50px;
  object-fit: contain;
  margin: 0 auto;
}

.contact_content_box {
  width: 550px;
  margin: 0 auto;
  margin-bottom: 80px;
}

@media (max-width: 950px) and (min-width: 500px) {
  .contact_content_box {
    width: 350px;
  }
}

@media (max-width: 500px) and (min-width: 0) {
  .contact_content_box {
    width: 90vw;
  }
}

.contact_content_box > h3 {
  width: 100%;
  text-align: left;
  font-size: 15px;
  color: #222222;
}

.contact_content_box > input {
  width: 100%;
  border: none;
  border-bottom: .5px solid  #373737;
  height: 25px;
  margin-top: 30px;
  padding-bottom: 10px;
  line-height: 25px;
  font-size: 18px;
  color: #373737;
  border-radius: 0;
}

.contact_content_box_area {
  width: 100%;
  border: none;
  margin-top: 30px;
  line-height: 25px;
  font-size: 18px;
  color: #373737;
  resize: none;
  border-radius: 0;
  margin-bottom: 7px;
}

.contact_content_box > div {
  width: 100%;
  height: .5px;
  background-color: #373737;
}

.contact_content_box > p {
  white-space: pre-wrap;
  width: 100%;
  border: none;
  border-bottom: .5px solid  #373737;
  margin-top: 30px;
  padding-bottom: 10px;
  line-height: 25px;
  min-height: 25px;
  font-size: 18px;
  color: #373737;
  text-align: left;
  background-color: #fafafa;
}

.contact_content_btn {
  width: 200px;
  height: 50px;
  line-height: 50px;
  color: #222222;
  background-color: white;
  border: 1px solid #222222;
  margin: 0 auto;
}

.contact_content_btn:hover {
  border: none;
  background-color: #222222;
  color: white;
}

.contact_content_second_btn {
  display: flex;
  justify-content: space-between;
  width: 450px;
  margin: 0 auto;
}

@media (max-width: 950px) and (min-width: 0px) {
  .contact_content_btn {
    width: 250px;
    margin-bottom: 30px;
  }
}

@media (max-width: 950px) and (min-width: 0px) {
  .contact_content_second_btn {
    width: 300px;
    display: block;
  }  
}

.contact_content_alart {
  width: 550px;
  margin: -50px auto 70px;
  color: rgb(255, 61, 61);
  font-size: 13px;
  text-align: left;
}

.contact_content_third {
  font-size: 13px;
  color: #444444;
  text-align: center;
  line-height: 30px;
  margin-top: 30px;
}





