.hojokin > div {
  height: 94vh;
  width: 96vw;
  background-size: cover;
  background-image: url(./../../../../image/hojokin/header.png);
  margin: 3vh 2vw;
  border-radius: 5px;
}

@media (max-width: 500px) and (min-width: 0px) {
  .hojokin > div {
    height: 60vh;
    width: 96vw;
  }
}

.hojokin > div > h4 {
  text-align: right;
  font-size: 40px;
  color: white;
  padding: 40vh 50px 0 0;
  font-weight: 500;
  line-height: 70px;
}

@media (max-width: 850px) and (min-width: 600px) {
  .hojokin > div > h4 {
    text-align: center;
    padding: 40vh 0 0;
    font-size: 35px;
  }
}

@media (max-width: 600px) and (min-width: 500px) {
  .hojokin > div > h4 {
    text-align: center;
    padding: 40vh 0 0;
    font-size: 30px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .hojokin > div > h4 {
    text-align: center;
    padding: 25vh 0 0;
    font-size: 20px;
    line-height: 45px;
    font-weight: bold;
  }
}

