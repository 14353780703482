.simulator_simulator {
  background-color: #F8F6F7;
  padding: 100px 0 ;
  text-align: center;
}

.simulator_simulator_title_h4 {
  color: #222222;
  font-size: 25px;
  font-weight: bold;
  margin: 0px auto 80px;
  line-height: 40px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 700px) and (min-width: 0px) {
  .simulator_simulator_title_h4 {
    font-size: 22px;
  }
}

.simulator_simulator_title_hr {
  width: 100px;
  border: none;
  border-bottom: 1.5px solid #222222;
  margin: 0 auto 60px;
}


.simulator_simulator_content {
  width: 800px;
  margin-top: 10px;
  margin: 0 auto 90px;
  text-align: left;
}

@media (max-width: 800px) and (min-width: 600px) {
  .simulator_simulator_content {
    width: 600px;
    margin-top: 10px;
    margin: 0 auto 90px;
    text-align: left;
  }
}

@media (max-width: 600px) and (min-width: 499px) {
  .simulator_simulator_content {
    width: 450px;
    margin-top: 10px;
    margin: 0 auto 90px;
    text-align: left;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .simulator_simulator_content {
    width: 350px;
    margin-top: 10px;
    margin: 0 auto 90px;
    text-align: left;
  }
}

.simulator_simulator_content > h4 {
  color: #EB6231;
  font-size: 17px;
  font-weight: bold;
  line-height: 40px;
  margin: 20px 10px 20px;
  border-left: 3px solid #EB6231;
  outline-offset: -3px;
  padding: 15px 0 15px 30px;
  box-shadow: 0px 0px 2px #C7C7C7;
  background-color: white;
  border-radius: 3px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.simulator_simulator_content > div {
  width: 800px;
  display: flex;
  margin: 0px auto 0px;
  flex-wrap: wrap;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 800px) and (min-width: 600px) {
  .simulator_simulator_content > div {
    width: 600px;
  }
}

@media (max-width: 600px) and (min-width: 499px) {
  .simulator_simulator_content > div {
    width: 450px;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .simulator_simulator_content > div {
    width: 350px;
  }
}

.simulator_simulator_content_noInclude {
  width: 770px;
  background-color: white;
  border: none;
  height: 45px;
  line-height: 45px;
  color: #EB6231;
  font-weight: bold;
  border: .5px solid #c7c7c7;
  margin: 10px 15px;
  font-size: 13px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 800px) and (min-width: 600px) {
  .simulator_simulator_content_noInclude {
    width: 570px;
  }
}

@media (max-width: 600px) and (min-width: 499px) {
  .simulator_simulator_content_noInclude {
    width: 420px;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .simulator_simulator_content_noInclude {
    width: 340px;
    margin: 10px 5px;
  }
}

.simulator_simulator_content > div > a > button {
  border: none;
  width: 170px;
  height: 45px;
  line-height: 45px;
  color: #EB6231;
  font-weight: bold;
  border: .5px solid #cacaca;
  margin: 10px 15px;
  font-size: 13px;
  font-weight: 600;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 800px) and (min-width: 600px) {
  .simulator_simulator_content > div > a > button {
    width: 170px;
  }
}

@media (max-width: 600px) and (min-width: 499px) {
  .simulator_simulator_content > div > a > button {
    width: 195px;
  }
}

@media (max-width: 499px) and (min-width: 0px) {
  .simulator_simulator_content > div > a > button {
    width: 165px;
    margin: 10px 5px;
  }
}

.simulator_simulator_content_start {
  width: 180px;
  font-size: 13px;
  line-height: 40px;
  color: #EB6231;
  font-weight: bold;
  background-color: #EB6231;
  border: 0;
  color: white;
  margin: 10px 0 30px;
  padding: 0 20px;
  border-radius: 3px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}



