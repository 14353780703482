.bid_header_search {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 800px;
  margin: -35px calc((100vw - 850px) / 2) 0;
  padding: 0 25px;
  height: 70px;
  background-color: white;
  box-shadow: 0 0 2px rgb(219, 219, 219);
  z-index: 2;
}

.bid_header_search > select {
  width: 200px;
  height: 40px;
  border: none;
  background-color: #f5f5f5;
  text-indent: 1em;
  margin: 15px 0;
  border-radius: 0px;
  color: #222222;
}

.bid_header_search > input {
  width: 450px;
  height: 40px;
  border: none;
  background-color: #f5f5f5;
  text-indent: 1em;
  margin: 15px 0;
  border-radius: 0px;
  color: #222222;
}

.bid_header_search > button {
  margin: 15px 0;
  width: 100px;
  height: 40px;
  background-color: #D70A0A;
  line-height: 40px;
  border: none;
  color: white;
}

@media (max-width: 850px) and (min-width: 551px) {
  .bid_header_search {
    display: block;
    width: 500px;
    margin: -100px calc((100vw - 550px) / 2) 0;
    height: auto;
  }

  .bid_header_search > select {
    width: 100%;
  }

  .bid_header_search > input {
    width: 100%;
  }
  
  .bid_header_search > button {
    width: 100%;
  }
}

@media (max-width: 550px) and (min-width: 0px) {
  .bid_header_search {
    display: block;
    width: 80%;
    margin: -100px calc((100vw - (80% + 50px)) / 2) 0;
    height: auto;
    padding: 10px 25px;
  }

  .bid_header_search > select {
    width: 100%;
  }

  .bid_header_search > input {
    width: 100%;
  }
  
  .bid_header_search > button {
    width: 100%;
  }
}

