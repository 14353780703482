.simulator_Strong {
  width: 1000px;
  padding: 120px calc((100vw - 1000px) / 2) 100px;
  background-color: #eeeeee;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .simulator_Strong {
    width: 900px;
    padding: 120px calc((100vw - 900px) / 2) 100px;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .simulator_Strong {
    width: 750px;
    padding: 120px calc((100vw - 750px) / 2) 100px;
  }
}

@media (max-width: 800px) and (min-width: 450px) {
  .simulator_Strong {
    width: 430px;
    padding: 120px calc((100vw - 430px) / 2) 100px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .simulator_Strong {
    width: 350px;
    padding: 120px calc((100vw - 350px) / 2) 100px;
  }
}

.simulator_Strong > h3 {
  font-size: 22px;
  color: #222222;
  text-align: center;
  margin-bottom: 10px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.simulator_Strong > h3 > span {
  font-size: 30px;
  color: #EC6104;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 800px) and (min-width: 0px) {
  .simulator_Strong > h3 {
    margin-bottom: 50px;
  }
}

.simulator_Strong > p {
  font-size: 13px;
  text-align: center;
  color: #222222;
  margin: 30px 0 70px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 450px) and (min-width: 0px) {
  .simulator_Strong > p > br {
    display: none;
  }
}

.Simulator_Strong_point {
  display: flex;
  text-align: center;
  width: 700px;
  margin: 50px auto 50px;
  justify-content: space-between;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .Simulator_Strong_point {
    width: 750px;
  }
}

@media (max-width: 800px) and (min-width: 0px) {
  .Simulator_Strong_point {
    display: none;
  }
}

.Simulator_Strong_point > div {
  width: 200px;
  margin: 0;
}

.Simulator_Strong_point > div > h6 {
  font-size: 50px;
  -webkit-text-stroke: .3px #222222;
}

.Simulator_Strong_point > div > img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  padding: 15px;
  border-radius: 100px;
  border: 1px solid #222222;
  background-color: white;
  margin: 10px 0 0 ;
}

.Simulator_Strong_point > div > h4 {
  font-size: 15px;
  margin: 20px 0 0;
  color: #222222;
}

.Simulator_Strong_point > div > p {
  font-size: 12px;
  margin: 10px 0 0;
  color: #222222;
}

.Simulator_strong_button {
  border: none;
  width: 230px;
  background-color: white;
  border: 1px solid #EC6104;
  color: #EC6104;
  line-height: 50px;
  text-align: center;
  border-radius: 100px;
  font-weight: bold;
  margin: 30px calc((100% - 230px) / 2);
}

.Simulator_strong_button:hover{
  border: 1px solid #EC6104;
  background-color: #EC6104;
  color: white;
}



