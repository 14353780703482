.simulator_desc {
  width: 80vw;
  min-height: 500px;
  background-image: url(./../../../../image/simulator/background.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 150px 10vw;
  margin: 150px auto 0px;
  border-radius: 10px;
}

.simulator_desc > div {
  border-radius: 10px;
}

.simulator_arrow {
  width: 100px;
  margin: -100px calc((100vw - 100px) / 2) 50px;
}

.speace {
  height: 100vh;
}

