.test_homeimage_image_box {
  position: relative;
  width: 100vw;
  height: 600px;
  margin: 130px 0vw 0;
}

.test_homeimage_image_box_frame {
  position: absolute;
  width: 93vw;
  margin: 0 3.5vw 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}

.test_homeimage_image_box_frame_arrow {
  width: 60px;
  height: 60px;
  background-color: #000000;
  margin-top: 280px;
  border-radius: 100px;
}

.test_homeimage_image_box_frame_arrow_icon {
  color: white;
  width: 25px;
  height: 25px;
}

.test_homeimage_image_box_frame_frame {
  width: calc(1200px - 2px);
  height: 598px;
  border: 1px solid #222222;
  border-radius: 10px;
  margin: 0 auto;
}

.test_homeimage_image {
  position: absolute;
  width: 1200px;
  margin: 0 calc((100vw - 1200px) / 2);
  height: 600px;
  border-radius: 10px;
  z-index: 1;
}

.test_homeimage_image_img {
  width: 1200px;
  height: 600px;
  object-fit: cover;
  border-radius: 10px;
}

.test_homeimage_bar {
  width: 1200px;
  margin: 30px calc((100vw - 1200px) / 2);
  display: flex;
  justify-content: space-between;
}

.test_homeimage_bar > div {
  width: calc((1200px / 6) - 20px);
  height: 70px;
  border-radius: 3px;
  background-color: #fafafa;
}

.test_homeimage_bar > div > p {
  font-size: 15px;
  color: white;
  padding: 10px 12px;
  line-height: 25px;
  font-weight: bold;
}
