.footer_help_nav {
  display: flex;
  width: 1100px;
  margin: 130px auto 130px;
  justify-content: space-between;
}

.footer_help_nav > a > div:nth-child(1) {
  display: flex;
}

.footer_help_nav > a > div:nth-child(1) > img {
  width: 30px;
  height: 30px;
}

.footer_help_nav > a > div:nth-child(1) > p {
  padding-left: 20px;
  line-height: 30px;
  text-decoration: none;
  color: #373737;
}

.footer_help_nav > div:nth-child(2) {
  display: flex;
  width: 300px;
  justify-content: space-between;
}

.footer_help_nav > div:nth-child(2) > a > p {
  text-decoration: none;
  color: #373737;
}

@media (max-width: 1100px) and (min-width: 1000px) {
  .footer_help_nav {
    width: 950px;
    margin: 275px calc((100vw - 950px) / 2) 100px;
  }
}

@media (max-width: 1000px) and (min-width: 900px) {
  .footer_help_nav {
    width: 850px;
    margin: 155px calc((100vw - 850px) / 2) 100px;
  }
}

@media (max-width: 900px) and (min-width: 700px) {
  .footer_help_nav {
    width: 700px;
    margin: 275px calc((100vw - 700px) / 2) 100px;
  }
}

@media (max-width: 700px) and (min-width: 500px) {
  .footer_help_nav {
    width: 90%;
    margin: 275px 5% 100px;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .footer_help_nav {
    width: 94vw;
    margin: 100px 3vw 50px;
    justify-content: space-between;
  }

  .footer_help_nav > div:nth-child(2) > p:nth-child(1) {
    display: none;
  }

  .footer_help_nav > div:nth-child(2) > p:nth-child(2) {
    display: none;
  }
}

