.photo_FA_title {
  width: 100vw;
  text-align: center;
  font-size: 30px;
  color: #444444;
}


.photo_question {
  width: 70vw;
  margin: 100px 15vw;
}

.photo_question_open {
  border: 1px solid #FF8D89;
  margin: 30px 0;
  border-radius: 5px;
  overflow: hidden;
  height: 110px;
  transition: height 0.7s;
}

.photo_question_close {
  border: 1px solid #FF8D89;
  margin: 30px 0;
  border-radius: 5px;
  overflow: hidden;
  height: 230px;
  transition: height 0.7s;
}

.photo_question_Q {
  display: flex;
  justify-content: space-between;
  padding: 35px 40px;
}

.photo_question_Q_div {
  display: flex;
}

.photo_question_Q_div > h4 {
  line-height: 33px;
  font-size: 35px;
  color: #FF8D89;
}

.photo_question_Q_div > h5 {
  line-height: 33px;
  font-size: 20px;
  color: #444444;
  margin-left: 20px;
  text-align: left;
}

.photo_question_icon {
  width: 30px;
  height: 30px;
  color: #FF8D89;
}

.photo_question_hr {
  width: calc(70vw - 80px);
  height: 1px;
  margin: 10px 40px;
  background-color: #dddddd;
}

.photo_question_A_open {
  overflow: hidden;
}

.photo_question_A_div {
  display: flex;
  padding: 35px 40px;
}

.photo_question_A_div > h4 {
  line-height: 33px;
  font-size: 35px;
  color: #999999;
}

.photo_question_A_div > h5 {
  line-height: 33px;
  font-size: 20px;
  color: #444444;
  margin-left: 20px;
}

@media (max-width: 1100px) and (min-width: 800px) {
  .photo_question {
    width: 90vw;
    margin: 100px 5vw;
  }

  .photo_question_hr {
    width: calc(90vw - 80px);
    height: 1px;
    margin: 10px 40px;
    background-color: #dddddd;
  }
  
}

@media (max-width: 800px) and (min-width: 450px) {
  .photo_question {
    width: 90vw;
    margin: 100px 5vw;
  }

  .photo_question_Q {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
  }

  .photo_question_hr {
    width: calc(90vw - 40px);
    height: 1px;
    margin: 10px 20px;
    background-color: #dddddd;
  }

  .photo_question_Q_div > h5 {
    line-height: 33px;
    font-size: 15px;
    color: #444444;
    margin-left: 20px;
  }

  .photo_question_A_div {
    display: flex;
    padding: 20px 20px;
  }
  
  .photo_question_A_div > h4 {
    line-height: 33px;
    font-size: 35px;
    color: #999999;
  }
  
  .photo_question_A_div > h5 {
    line-height: 33px;
    font-size: 15px;
    color: #444444;
    margin-left: 20px;
  }

  .photo_question_open {
    border: 1px solid #FF8D89;
    margin: 30px 0;
    border-radius: 5px;
    overflow: hidden;
    height: 80px;
    transition: height 0.7s;
  }
  
  .photo_question_close {
    border: 1px solid #FF8D89;
    margin: 30px 0;
    border-radius: 5px;
    overflow: hidden;
    height: 170px;
    transition: height 0.7s;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .photo_question {
    width: 90vw;
    margin: 100px 5vw;
  }

  .photo_question_Q {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;
  }

  .photo_question_hr {
    width: calc(90vw - 40px);
    height: 1px;
    margin: 10px 20px;
    background-color: #dddddd;
  }

  .photo_question_Q_div > h4 {
    line-height: 66px;
    font-size: 35px;
    color: #FF8D89;
  }
  
  .photo_question_Q_div > h5 {
    line-height: 33px;
    font-size: 15px;
    color: #444444;
    margin-left: 20px;
  }

  .photo_question_A_div {
    display: flex;
    padding: 20px 20px;
  }
  
  .photo_question_A_div > h4 {
    line-height: 33px;
    font-size: 35px;
    color: #999999;
  }
  
  .photo_question_A_div > h5 {
    line-height: 33px;
    font-size: 15px;
    color: #444444;
    margin-left: 20px;
  }

  .photo_question_open {
    border: 1px solid #FF8D89;
    margin: 30px 0;
    border-radius: 5px;
    overflow: hidden;
    height: 110px;
    transition: height 0.7s;
  }
  
  .photo_question_close {
    border: 1px solid #FF8D89;
    margin: 30px 0;
    border-radius: 5px;
    overflow: hidden;
    height: 210px;
    transition: height 0.7s;
  }
}

