.bid_api_header_bar {
  position: fixed;
  width: 100%;
  height: 10px;
  background-color: #1F2C5B;
}

/* left */

.bid_api_header_left {
  position: fixed;
  top: 200px;
  left: calc((100% - 1200px) / 2);
  width: 25px;
}

.bid_api_header_left > div {
  width: 10px;
  height: 10px;
  background-color: #D70A0A;
  margin: 50px 7px 0;
  border-radius: 100px;
}

.bid_api_header_left > h3 {
  width: 25px;
  text-align: center;
  line-height: 19px;
  font-size: 15px;
  margin-top: 20px;
  color: #222222;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

/* 全体 */

.bid_api {
  width: 100%;
  background-color: #fafafa;
  padding-bottom: 50px;
}

.bid_api_media {
  width: 700px;
  padding-top: 100px;
  margin: 0 auto;
}

@media (max-width: 700px) and (min-width: 0px) {
  .bid_api_media {
    width: 90%;
  }
}

.bid_api_media > h2 {
  color: #222222;
  font-size: 30px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.bid_api_media > div {
  display: flex;
  margin: 50px 0 30px;
}

.bid_api_media > div > p {
  width: 20px;
  height: 20px;
  color: white;
  text-align: center;
  font-size: 10px;
  border-radius: 100px;
  font-weight: 400;
  line-height: 20px;
  margin: 5px 0;
  overflow-wrap:break-word;
}

.bid_api_media > div > h3 {
  font-size: 18px;
  color: #222222;
  line-height: 30px;
  margin-left: 20px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.bid_api_media > p {
  font-size: 16px;
  line-height: 30px;
  color: #222222;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  margin-bottom: 100px;
  white-space: pre-line;
  overflow-wrap:break-word;
}

.bid_api_media_table {
  width: 100%;
  border: 1px solid #222222;
  border-collapse: collapse;
  margin-bottom: 100px;
}

.bid_api_media_table > * p {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.bid_api_media_q {
  width: 20%;
  background-color: #D70A0A;
  color: white;
  border: 1px solid #222222;
  font-weight: bold;
}

.bid_api_media_a {
  width: 80%;
  background-color: white;
  color: #222222;
  text-align: left;
  border: 1px solid #222222;
}

.bid_api_media > a > button {
  border: none;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  line-height: 40px;
  color: #222222;
  background-color: #D70A0A;
  color: white;
  border-radius: 2px;
  border: 0.5px solid #222222;
  margin: 0 0 50px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

