.photo_download {
  width: 100vw;
  padding: 80px 0;
  background-color: #FF8D89;
  margin: 100px 0;
  text-align: center;
}

.photo_download > h4 {
  color: white;
  font-size: 30px;
}

.photo_download > a > div {
  width: 180px;
  line-height: 48px;
  color: white;
  font-size: 20px;
  border-radius: 5px;
  border: 1.5px solid white;
  margin: 30px calc(50vw - 88.5px) 0;
  font-weight: bold;
}

.photo_download > a > div:hover {
  background-color: white;
  color: #FF8D89;
}

@media (max-width: 450px)  {
  .photo_download {
    padding: 60px 0;
  }

  .photo_download > h4 {
    color: white;
    font-size: 26px;
  }
}

/* ==================== */

.photo_salon {
  text-align: center;
  width: 100vw;
  padding: 80px 0;
  background-color: #FAFAFA;
  margin: 100px 0;
}

.photo_salon > h4 {
  color: #444444;
  font-size: 30px;
}

.photo_salon > a > div {
  width: 180px;
  line-height: 48px;
  color: #444444;
  font-size: 20px;
  border-radius: 5px;
  border: 1.5px solid #444444;
  margin: 30px calc(50vw - 88.5px) 0;
  font-weight: bold;
}

.photo_salon > a > div:hover {
  background-color: #444444;
  color: white;
}

@media (max-width: 450px)  {
  .photo_salon {
    padding: 60px 0;
  }

  .photo_salon > h4 {
    color: #444444;
    font-size: 20px;
  }
}

/* ================== */

.photo_footer {
  background-color: #FAFAFA;
  padding: 30px 0 50px;
}

.photo_footer > h4 {
  color: white;
  font-size: 15px;
  line-height: 60px;
}

.photo_footer_nav {
  width: 60vw;
  padding: 0 20vw;
  height: 60px;
  /* background-color: #f9f9f9; */
  line-height: 60px;
  display: flex;
  color: #444444;
  justify-content: space-between;
  margin-top: 200px;
  border-top: 1px solid #FAFAFA;
}

/* ====================== */

.photo_blog_title {
  width: 100vw;
  text-align: center;
  font-size: 30px;
  color: #444444;
}






