.test_homeimage_service {
  width: 1200px;
  margin: 100px calc((100vw - 1200px) / 2);
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .test_homeimage_service {
    width: 960px;
    margin: 30px calc((100vw - 960px) / 2) 0;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .test_homeimage_service {
    width: 740px;
    margin: 30px calc((100vw - 740px) / 2) 0;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .test_homeimage_service {
    width: 540px;
    margin: 30px calc((100% - 540px) / 2) 0;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .test_homeimage_service {
    width: 90%;
    margin: 30px 5% 0;
  }
}

.test_homeimage_service_title {
  width: 100%;
  margin-bottom: 80px;
}

.test_homeimage_service_title > p {
  font-size: 15px;
  text-align: center;
  color: #222222;
  line-height: 10px;
}

.test_homeimage_service_title > h4 {
  font-size: 70px;
  text-align: center;
  color: #222222;
  line-height: 65px;
}

.test_homeimage_service_items {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .test_homeimage_service_items {
    display: none;
  }
}

.test_homeimage_service_items > div {
  width: 350px;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .test_homeimage_service_items > div {
    width: 300px;
  }
}

.test_homeimage_service_items > div > img {
  width: 350px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .test_homeimage_service_items > div > img {
    width: 300px;
    height: 180px;
  }
}

.test_homeimage_service_items > div > h4 {
  width: 100%;
  text-align: center;
  font-size: 20px;
  margin: 10px 0 0;
  line-height: 50px;
  color: #111111;
  font-weight: 600;
}

.test_homeimage_service_items > div > p {
  color: #111111;
  width: 90%;
  text-align: left;
  font-size: 15px;
  margin: 10px 5% 0;
  line-height: 28px;
}

.test_homeimage_service_items > div > a > button {
  width: 150px;
  height: 45px;
  border: none;
  margin: 30px calc((100% - 150px) / 2) 80px;
  border-radius: 1000px;
  color: white;
}

/* phone */

.test_homeimage_service_items_phone {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 10000px) and (min-width: 1001px) {
  .test_homeimage_service_items_phone {
    display: none;
  }
}

@media (max-width: 800px) and (min-width: 0px) {
  .test_homeimage_service_items_phone {
    width: 350px;
    margin: 0 calc((100% - 350px) / 2);
    display: block;
  }
}

.test_homeimage_service_items_phone > div {
  width: 350px;
}

.test_homeimage_service_items_phone > div > img {
  width: 350px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
}

.test_homeimage_service_items_phone > div > h4 {
  width: 100%;
  text-align: center;
  font-size: 20px;
  margin: 10px 0 0;
  line-height: 50px;
  color: #111111;
  font-weight: 900;
}

.test_homeimage_service_items_phone > div > p {
  color: #111111;
  width: 90%;
  text-align: left;
  font-size: 15px;
  margin: 10px 5% 0;
  line-height: 28px;
}

.test_homeimage_service_items_phone > div > a > button {
  width: 150px;
  height: 45px;
  border: none;
  margin: 30px calc((100% - 150px) / 2) 80px;
  border-radius: 1000px;
  color: white;
}

