.hojokin_result {
  width: 100vw;
  padding: 70px 0;
  border-top: 1px solid #C17300;
  border-bottom: 1px solid #C17300;
  background-color: #FFFBF4;
}

@media (max-width: 700px) and (min-width: 500px) {
  .hojokin_result {
    width: 90vw;
    padding: 70px 5vw;
  }
}

.hojokin_result > h4 {
  font-size: 28px;
  color: #BF6E00;
  text-align: center;
  margin-bottom: 40px;
}

.hojokin_result_box {
  margin: 20px auto 0;
  display: flex;
  width: 700px;
  justify-content: space-between;
}

@media (max-width: 1000px) and (min-width: 500px) {
  .hojokin_result_box {
    display: block;
    width: 80vw;
    margin: 20px auto 0;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .hojokin_result_box {
    display: block;
    width: 80vw;
    margin: 20px auto 0;
  }
}

.hojokin_result_box > div {
  width: 200px;
  height: 200px;
  background-image: url(./../../../../image//adbusiness/header_award.png);
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 1000px) and (min-width: 500px) {
  .hojokin_result_box > div {
    width: 250px;
    height: 250px;
    margin: 0 auto;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .hojokin_result_box > div {
    width: 200px;
    height: 200px;
    margin: 0 calc(40vw - 100px);
  }
}

.hojokin_result_box > div > h4 {
  color: #D28741;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
}

@media (max-width: 1000px) and (min-width: 500px) {
  .hojokin_result_box > div > h4 {
    font-size: 50px;
  }
}

.hojokin_result_box > div > h5 {
  color: #D28741;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  padding-top: 40px;
}

@media (max-width: 1000px) and (min-width: 500px) {
  .hojokin_result_box > div > h5 {
    font-size: 25px;
  }
}