.memorii_photo_update {
  width: 400px;
  height: 100vh;
  margin: 30px calc((100vw - 400px) / 2);
}

.memorii_photo_update > button {
  width: 400px;
  height: 45px;
  margin: 20px auto;
  background-color: #373737;
  color: white;
  font-weight: bold;
  border: none;
}