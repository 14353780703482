
.photo_structure_title {
  margin-top: 80px;
  font-size: 30px;
  color: #444444;
  font-weight: bold;
  text-align: center;
}

.photo_structure {
  width: 65vw;
  padding: 20px 2.5vw;
  background-color: #FF8D89;
  border-radius: 5px;
  margin-top: 50px;
  margin: 50px 15vw 0;
}

.photo_structure > img{
  width: 65vw;
}

@media (max-width: 800px) and (min-width: 00px) {
  .photo_structure {
    width: 80vw;
    padding: 20px 5vw;
    background-color: #FF8D89;
    border-radius: 5px;
    margin-top: 50px;
    margin: 50px 5vw 0;
  }
  
  .photo_structure > img{
    width: 80vw;
  }
}