.photo_point_title {
  width: 200px;
  line-height: 40px;
  background-color: #FF8D89;
  color: white;
  font-weight: bold;
  border-radius: 100px;
  margin: 150px auto 0px;
  text-align: center;
}

.photo_point {
  margin: 50px 7.5vw;
  width: 85vw;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
}

.photo_point > div {
  width: 350px;
  background-color: #FFE8E7;
  border-radius: 5px;
  margin-bottom: 50px;
}

.photo_point > div > div {
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background-color: white;
  border: 2px solid #FF8D89;
  margin: 30px auto 0;
}

.photo_point_icons {
  width: 30px;
  height: 30px;
  color: #FF8D89;
  margin: 15px;
}

.photo_point > div > h4 {
  font-size: 15px;
  line-height: 25px;
  color: #2f2f2f;
  padding: 15px 40px 0;
}

.photo_point > div > p {
  font-size: 13px;
  line-height: 25px;
  color: #464646;
  padding: 10px 40px 20px;
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .photo_point {
    margin: 50px 5vw;
    width: 90vw;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}

@media (max-width: 1200px) and (min-width: 450px) {
  .photo_point {
    margin: 50px 0vw;
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .photo_point > div {
    width: 330px;
    background-color: #FFE8E7;
    border-radius: 5px;
    margin-bottom: 50px;
  }
}

@media (max-width: 450px) {
  .photo_point {
    margin: 50px 0vw;
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .photo_point > div {
    width: 330px;
    background-color: #FFE8E7;
    border-radius: 5px;
    margin-bottom: 50px;
  }
}
