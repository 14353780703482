.help_bolg_title {
  padding: 80px calc((100% - 1100px) / 2) 0px;
  color: #222222;
  font-size: 40px;
  text-align: center;
  background-color: white;
}

@media (max-width: 1100px) and (min-width: 1000px) {
  .help_bolg_title {
    padding: 150px calc((100% - 1000px) / 2) 0px;
    width: 1000px;
  }
}

@media (max-width: 1000px) and (min-width: 900px) {
  .help_bolg_title {
    padding: 150px calc((100% - 850px) / 2) 0px;
    width: 850px;
  }
}

@media (max-width: 900px) and (min-width: 500px) {
  .help_bolg_title {
    padding: 150px calc((100% - 450px) / 2) 0px;
    width: 450px;
    text-align: center;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_bolg_title {
    padding: 50px 0 0px;
    width: 100%;
    text-align: center;
  }
}

.help_bolg_desc {
  color: #373737;
  font-size: 15px;
  text-align: center;
}

.help_bolg {
  display: flex;
  width: 1100px;
  margin: 80px auto 100px;
  justify-content: space-between;
  background-color: white;
}

@media (max-width: 1100px) and (min-width: 1000px) {
  .help_bolg {
    margin: 80px calc((100% - 1000px) / 2) 0px;
    width: 1000px;
  }
}

@media (max-width: 1000px) and (min-width: 900px) {
  .help_bolg {
    margin: 80px calc((100% - 850px) / 2) 0px;
    width: 850px;
  }
}

@media (max-width: 900px) and (min-width: 500px) {
  .help_bolg {
    display: block;
    width: 450px;
    margin: 80px calc((100% - 450px) / 2) 0px;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_bolg {
    display: flex;
    margin: 80px 3% 0px;
    width: 94%;
    flex-wrap: wrap;
  }
}

.help_bolg > a > div {
  width: 330px;
}

@media (max-width: 1000px) and (min-width: 900px) {
  .help_bolg > a > div {
    width: 260px;
  }
}

@media (max-width: 900px) and (min-width: 500px) {
  .help_bolg > a > div {
    margin: 0 calc((100% - 330px) / 2) 50px;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_bolg > a > div {
    width: 94%;
    margin: 0 3% 50px;
  }
}

.help_bolg > a > div > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

@media (max-width: 1100px) and (min-width: 1000px) {
  .help_bolg > a > div > img {
    height: 180px;
  }
}

@media (max-width: 1000px) and (min-width: 900px) {
  .help_bolg > a > div > img {
    height: 170px;
  }
}

.help_bolg > a > div > p {
  width: calc(100% - 20px);
  text-align: left;
  color: #444444;
  font-size: 15px;
  line-height: 33px;
  padding: 10px 10px 0;
}




