.bid_strong_items {
  width: 1000px;
  margin: 50px calc((100vw - 1000px) / 2) 0;
  display: flex;
  justify-content: space-between;
}

.bid_strong_items > div {
  width: 300px;
  height: 350px;
  background-color: #FAFAFA;
}

.bid_strong_items > div > img {
  width: 130px;
  height: 130px;
  margin: 10px calc((300px - 130px) / 2);
  object-fit: contain;
}

.bid_strong_items > div > h4 {
  width: 250px;
  margin: 10px 25px;
  object-fit: contain;
  text-align: center;
  line-height: 25px;
}

.bid_strong_items > div > p {
  width: 250px;
  margin: 15px 25px;
  object-fit: contain;
  text-align: left;
  font-size: 13px;
  color: #373737;
}

/* 800px */

@media (max-width: 1000px) and (min-width: 801px) {
  .bid_strong_items {
    width: 800px;
    margin: 50px calc((100vw - 800px) / 2) 0;
  }

  .bid_strong_items > div {
    width: 250px;
    height: 350px;
    background-color: #FAFAFA;
  }

  .bid_strong_items > div > img {
    width: 130px;
    height: 130px;
    margin: 10px calc((250px - 130px) / 2);
    object-fit: contain;
  }

  .bid_strong_items > div > h4 {
    width: 210px;
    margin: 10px 20px;
  }
  
  .bid_strong_items > div > p {
    width: 210px;
    margin: 15px 20px;
  }
}

/* 600px */

@media (max-width: 800px) and (min-width: 601px) {
  .bid_strong_items {
    display: block;
    width: 300px;
    margin: 50px calc((100vw - 300px) / 2) 0;
  }

  .bid_strong_items > div {
    margin-bottom: 30px;
  }
}

/* 600px */

@media (max-width: 600px) and (min-width: 0px) {
  .bid_strong_items {
    display: block;
    width: 300px;
    margin: 50px calc((100% - 300px) / 2) 0;
  }

  .bid_strong_items > div {
    width: 300px;
    margin: 0 0 30px;
  }
}





