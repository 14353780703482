.hojokin_QA {
  width: 800px;
  padding: 100px calc((100vw - 800px) / 2);
  background-color: #F2F2F2;
}

@media (max-width: 800px) and (min-width: 450px) {
  .hojokin_QA {
    width: 450px;
    padding: 120px calc((100vw - 450px) / 2) 100px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .hojokin_QA {
    width: 350px;
    padding: 120px calc((100vw - 350px) / 2) 100px;
  }
}

.hojokin_QA > h4 {
  font-size: 25px;
  text-align: center;
}

.hojokin_QA_box {
  width: 800px;
  border: 1px solid #39302C;
  border-radius: 5px;
  margin: 80px auto 0;
  background-color: white;
}

@media (max-width: 800px) and (min-width: 450px) {
  .hojokin_QA_box {
    width: 450px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .hojokin_QA_box {
    width: 350px;
  }
}

.hojokin_QA_box > div {
  border-bottom: 1px solid #39302C;
}

.hojokin_QA_q {
  display: flex;
  background-color: #19347A;
  color: white;
}

.hojokin_QA_q >  h3 {
  font-size: 20px;
  padding: 20px;
  line-height: 30px;
}

.hojokin_QA_q >  h4 {
  font-size: 15px;
  padding: 20px;
  line-height: 30px;
}

.hojokin_QA_a {
  display: flex;
  color: #222222;
  padding: 10px 0;
}

.hojokin_QA_a >  h3 {
  font-size: 20px;
  padding: 20px;
}

.hojokin_QA_a >  h4 {
  font-size: 15px;
  padding: 20px;
  font-weight: 300;
  color: #222222;
}

.hojokin_QA_btn {
  text-decoration: none;
  color: #222222;
  width: 200px;
  height: 45px;
  line-height: 45px;
  font-size: 15px;
  margin: 50px auto 0;
  border: 1px solid #222222;
  border-radius: 100px;
  text-align: center;
  font-weight: bold;
  background-color: white;
}

.hojokin_QA_btn:hover {
  color: white;
  border: 1px solid #222222;
  background-color: #222222;
}



