.AdBusiness_what_box {
  margin: 100px auto;
}

.AdBusiness_what_bottom {
  width: 1000px;
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .AdBusiness_what_bottom {
    width: 900px;
    margin: 50px auto;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_what_bottom {
    width: 750px;
    margin: 0px auto;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .AdBusiness_what_bottom {
    width: 550px;
    display: block;
    margin: 0 auto;
  }
}

@media (max-width: 600px) and (min-width: 450px) {
  .AdBusiness_what_bottom {
    width: 450px;
    display: block;
    margin: 0 auto;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_what_bottom {
    width: 350px;
    display: block;
    margin: 0 auto;
  }
}








