.simulator_contact {
  width: 800px;
  text-align: center;
  margin: 150px auto 100px;
  border: 1.5px solid #EB6231;
  border-radius: 3px;
  padding: 40px 0;
  background-color: white;
}

@media (max-width: 700px) and (min-width: 0px) {
  .simulator_contact {
    width: 90vw;
  }
}

.simulator_contact > h4 {
  font-size: 23px;
  color: #EB6231;
  margin-bottom: 20px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.simulator_contact > p {
  font-size: 15px;
  color: #3C3A2B;
  margin-bottom: 30px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 700px) and (min-width: 0px) {
  .simulator_contact > p {
    margin:  0 20px 20px;
  }

  .simulator_contact > p > br {
    display: none;
  }
}

.simulator_contact > a > button {
  border: none;
  width: 200px;
  height: 50px;
  color: white;
  font-weight: bold;
  background-color: #EB6231;
  border-radius: 100px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}


