.AdBusiness_Strong_point {
  display: flex;
  text-align: center;
  width: 800px;
  margin: 50px auto 150px;
  justify-content: space-between;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_Strong_point {
    width: 750px;
  }
}

@media (max-width: 800px) and (min-width: 0px) {
  .AdBusiness_Strong_point {
    display: none;
  }
}

.AdBusiness_Strong_point > div {
  width: 200px;
  margin: 0;
  text-align: center;
}

.AdBusiness_Strong_point > div > h6 {
  font-size: 50px;
  -webkit-text-stroke: .3px #222222;
}

.AdBusiness_Strong_point > div > img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  padding: 15px;
  border-radius: 100px;
  border: 1px solid #222222;
  background-color: white;
  margin: 10px 45px 0;
}

.AdBusiness_Strong_point > div > h4 {
  font-size: 15px;
  margin: 20px 0 0;
  color: #222222;
}

.AdBusiness_Strong_point > div > p {
  font-size: 12px;
  margin: 10px 0 0;
  color: #222222;
}