.simulator_contact_form {
  width: 450px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #222222;
  margin: 100px auto 50px;
  text-align: center;
}

@media (max-width: 450px) and (min-width: 0px) {
  .simulator_contact_form {
    width: 350px;
  }
}

.simulator_contact_form > h4 {
  font-size: 18px;
  margin: 40px auto 10px;
  color: #EC6104;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.simulator_contact_form > p {
  width: 330px;
  font-size: 12px;
  margin: 0 auto 30px;
  color: #373737;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 450px) and (min-width: 0px) {
  .simulator_contact_form > p {
    width: 300px;
  }
}

.simulator_contact_form_input {
  width: 330px;
  text-align: left;
  margin: 0 60px 10px;
  background-color: white;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 450px) and (min-width: 0px) {
  .simulator_contact_form_input {
    width: 300px;
    margin: 0 25px 10px ;
  }
}

.simulator_contact_form_input > h5 {
  width: 330px;
  font-size: 13px;
  line-height: 30px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 450px) and (min-width: 0px) {
  .simulator_contact_form_input > h5 {
    width: 300px;
  }
}

.simulator_contact_form_input > input {
  width: 330px;
  border: none;
  height: 35px;
  background-color: white;
}

@media (max-width: 450px) and (min-width: 0px) {
  .simulator_contact_form_input > input {
    width: 300px;
  }
}

.simulator_contact_form_input > div {
  width: 330px;
  height: .5px;
  background-color: #222222;
}

@media (max-width: 450px) and (min-width: 0px) {
  .simulator_contact_form_input > div {
    width: 300px;
  }
}

.simulator_contact_form_input > p {
  font-size: 10px;
  color: red;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.simulator_contact_form_context {
  width: 330px;
  text-align: left;
  margin: 0 60px 10px;
  background-color: white;
}

@media (max-width: 450px) and (min-width: 0px) {
  .simulator_contact_form_context {
    width: 300px;
    margin: 0 25px 10px ;
  }
}

.simulator_contact_form_context > h5 {
  width: 330px;
  font-size: 13px;
  line-height: 30px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 450px) and (min-width: 0px) {
  .simulator_contact_form_context > h5 {
    width: 300px;
  }
}

.simulator_contact_form_context > textarea {
  width: 330px;
  border: none;
  height: 100px;
  background-color: white;
  resize: none;
}

@media (max-width: 450px) and (min-width: 0px) {
  .simulator_contact_form_context > textarea {
    width: 300px;
  }
}

.simulator_contact_form_context > div {
  width: 330px;
  height: .5px;
  background-color: #222222;
}

@media (max-width: 450px) and (min-width: 0px) {
  .simulator_contact_form_context > div {
    width: 300px;
  }
}

.simulator_contact_form_context > p {
  font-size: 10px;
  color: red;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.simulator_contact_form_btn {
  border: none;
  width: 180px;
  background-color: #EC6104;
  color: white;
  line-height: 45px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  margin: 30px calc((100% - 180px) / 2);
}


