.hojokin_point {
  width: 1000px;
  margin: 200px calc(50vw - 500px);
}

@media (max-width: 1000px) and (min-width: 500px) {
  .hojokin_point {
    width: 800px;
    margin: 200px calc(50vw - 400px);
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .hojokin_point {
    width: 350px;
    margin: 200px auto;
  }
}

.hojokin_point_title {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .hojokin_point_title {
    display: block;
  }
}

.hojokin_point_title > div {
  width: 300px;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .hojokin_point_title > div {
    margin: 0 auto;
    text-align: center;
  }
}

.hojokin_point_title > div > h5 {
  font-size: 25px;
  color: #19347A;
}

.hojokin_point_title > div > div {
  width: 130px;
  height: 1px;
  background-color: #19347A;
  margin: 30px 0 0 0;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .hojokin_point_title > div > div {
    margin: 30px auto 0;
  }
}

.hojokin_point_title > p {
  font-size: 15px;
  width: 650px;
  font-weight: 500;
  margin: 30px 0 0;
  line-height: 35px;
}

@media (max-width: 1000px) and (min-width: 700px) {
  .hojokin_point_title > p {
    margin: 30px auto 0;
    text-align: center;
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .hojokin_point_title > p {
    width: 80vw;
    margin: 30px auto 0;
    text-align: center;
  }
}

.hojokin_point_box {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 80px 0 0;
}

@media (max-width: 1000px) and (min-width: 700px) {
  .hojokin_point_box {
    width: 650px;
    margin: 80px auto 0;
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .hojokin_point_box {
    width: 310px;
    margin: 80px auto 0;
  }
}

.hojokin_point_box > div {
  position: relative;
  width: 310px;
  height: 220px;
  background-color: #19347A;
  margin: 20px 0 0 0;
}

.hojokin_point_box > div > img {
  top: 0;
  position: absolute;
  width: 310px;
  height: 220px;
  object-fit: cover;
  opacity: .4;
}

.hojokin_point_box > div > h4 {
  position: absolute;
  font-size: 20px;
  color: white;
  z-index: 2;
  margin: 40px 0 0;
  text-align: center;
  width: 310px;
}

.hojokin_point_box > div > p {
  position: absolute;
  font-size: 12px;
  color: white;
  z-index: 2;
  margin: 90px 20px 0;
  text-align: center;
  width: 270px;
  line-height: 30px;
}
