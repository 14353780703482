.task_mypage {
  position: relative;
  width: 100%;
  background-color: #F5F2EA;
}

.task_mypage_box {
  position: absolute;
  width: 1150px;
  padding: 150px calc((100vw - 1150px) / 2);
  display: flex;
  justify-content: space-between;
  background-color: #F5F2EA;
}

.task_mypage_left {
  width: 700px;
}

.task_mypage_left_top {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.task_mypage_left_top > div {
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 5px;
}

.task_mypage_left_top > div > h4 {
  width: 200px;
  font-size: 18px;
  color: white;
  text-align: center;
  line-height: 40px;
  margin-top: 50px;
}

.task_mypage_left_top > div > p {
  width: 200px;
  font-size: 30px;
  color: white;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
}

.task_mypage_left_bottom {
  position: relative;
  margin-top: 50px;
  background-color: white;
  width: 100%;
  height: 400px;
  display: flex;
  border-radius: 5px;
}

.task_mypage_left_bottom_nomal {
  position: absolute;
  display: flex;
  z-index: 1;
}

.task_mypage_left_bottom_drop {
  position: absolute;
  width: 100%;
  height: 400px;
  opacity: .5;
  background-image: url(./../../../../image/task/drop.png);
  background-size: cover;
  z-index: 2;
}

.task_mypage_left_bottom_unit {
  top: 0;
  height: 300px;
  margin: 0 0 0 30px;
  padding-top: 50px;
  width: 20px;
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-flow:  column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.task_mypage_left_bottom_unit > p {
  color: #222222;
  font-size: 14px;
}

.task_mypage_left_bottom_graph {
  top: 0;
  margin: 50px 0 0 50px;
  width: 550px;
}

.task_mypage_left_bottom_graph_top {
  width: 500px;
  height: 300px;
  border-left: .5px solid #EBEBEB;
  border-bottom: .5px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
}

.task_mypage_left_bottom_graph_top > div {
  width: 15px;
  background-color: #325445;
  margin: 0 7.5px;
}

.task_mypage_left_bottom_graph_bottom {
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
}

.task_mypage_left_bottom_graph_bottom > p {
  width: 30px;
  color: #222222;
  font-size: 13px;
  margin-top: 5px;
  text-align: center;
}



.task_mypage_right {
  width: 350px;
  height: 650px;
  background-color: white;
  border-radius: 5px;
}

.task_mypage_right_title {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 15px;
  border-bottom: .5px solid #444444;
  font-size: 13px;
  margin-bottom: 10px;
}

.task_mypage_right_title > p {
  color: #222222;
  line-height: 30px;
  font-size: 13px;
  font-weight: bold;
}

.task_mypage_right_title > button {
  width: 60px;
  text-align: center;
  line-height: 30px;
  color: white;
  background-color: #222222;
  border: none;
}

.task_mypage_right_list {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px;
}

.task_mypage_right_list > p {
  font-size: 13px;
  color: #222222;
  line-height: 30px;
}

.task_mypage_right_list > button {
  width: 60px;
  text-align: center;
  line-height: 30px;
  color: white;
  background-color: #D1505F;
  border: none;
}


.task_table_mytask {
  font-size: 15px;
  line-height: 40px;
  color: #222222;
  padding: 0 20px;
  width: 310px;
}



.task_mypage_awards {
  z-index: 2;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
}

.task_mypage_awards > div {
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  opacity: .3;
}



.hero_image {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.flip_horizontal {
  transform: scaleX(-1);
}
