.help_subsidy_title {
  width: 80vw;
  padding: 150px 10vw 80px;
  background-color: #fafafa;
  display: flex;
  justify-content: space-between;
}

.help_subsidy_title > h4 {
  font-size: 30px;
  color: #222222;
  text-align: left;
  line-height: 55px;
}

@media (max-width: 900px) and (min-width: 500px) {
  .help_subsidy_title > h4 {
    font-size: 26px;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_subsidy_title > h4 {
    font-size: 26px;
  }
}

.help_subsidy_title > a > div {
  width: 200px;
  height: 35px;
  color: #222222;
  background-color: white;
  padding: 10px 30px;
  border-radius: 100px;
  line-height: 35px;
  border: 1px solid #222222;
}

@media (max-width: 900px) and (min-width: 600px) {
  .help_subsidy_title > a > div {
    width: 150px;
  }
}

@media (max-width: 600px) and (min-width: 500px) {
  .help_subsidy_title > a > div {
    display: none;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_subsidy_title > a > div {
    display: none;
  }
}

.help_subsidy {
  width: 80vw;
  padding: 0 10vw 0;
  background-color: #fafafa;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

@media (max-width: 1000px) and (min-width: 850px) {
  .help_subsidy {
    flex-wrap: wrap;
    width: 60vw;
    margin: 0 auto;
    padding: 0 20vw;
  }
}

@media (max-width: 850px) and (min-width: 750px) {
  .help_subsidy {
    flex-wrap: wrap;
    width: 70vw;
    margin: 0 auto;
    padding: 0 15vw;
  }
}

@media (max-width: 750px) and (min-width: 500px) {
  .help_subsidy {
    flex-wrap: wrap;
    width: 80vw;
    margin: 0 auto;
    padding: 0 10vw;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_subsidy {
    flex-wrap: wrap;
    width: 80vw;
    margin: 0 auto;
    padding: 0 10vw;
  }
}

.help_subsidy > div {
  width: 250px;
  height: 550px;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 1250px) and (min-width: 1000px) {
  .help_subsidy > div {
    width: 200px;
    height: 350px;
  }
}

@media (max-width: 1000px) and (min-width: 650px) {
  .help_subsidy > div {
    width: 250px;
    height: 550px;
  }
}

@media (max-width: 650px) and (min-width: 500px) {
  .help_subsidy > div {
    width: 200px;
    height: 350px;
    margin-bottom: 50px;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_subsidy > div {
    width: 250px;
    height: 500px;
    margin-bottom: 100px;
    margin: 0 auto;
  }
}

.help_subsidy > div:nth-child(1) {
  background-image: url(./../../../../image/help/subsidy1_1.png);
}

.help_subsidy > div:nth-child(2) {
  background-image: url(./../../../../image/help/subsidy2_1.png);
}

.help_subsidy > div:nth-child(3) {
  background-image: url(./../../../../image/help/subsidy3_1.png);
}

.help_subsidy > div:nth-child(4) {
  background-image: url(./../../../../image/help/subsidy4_1.png);
}

.help_subsidy > div > img {
  width: 130px;
  height: 130px;
  padding: 40px calc((250px - 130px) / 2) 0;
}

.help_subsidy > div > h5 {
  font-size: 18px;
  padding-top: 20px;
  padding: 15px 20px 0;
  text-align: left;
}

@media (max-width: 1250px) and (min-width: 500px)  {
  .help_subsidy > div > h5 {
    font-size: 15px;
  }
}

.help_subsidy > div > p {
  font-size: 13px;
  padding: 15px 20px 0;
  text-align: left;
  min-height: 50px;
}

@media (max-width: 1250px) and (min-width: 1000px) {
  .help_subsidy > div > p:nth-child(5) {
    display: none;
  }
}

@media (max-width: 1000px) and (min-width: 650px) {  
  .help_subsidy > div > p:nth-child(5) {
    display: block;
  }
}

@media (max-width: 650px) and (min-width: 500px) {
  .help_subsidy > div > p:nth-child(5) {
    display: none;
  }
}

.help_subsidy > div > div {
  display: flex;
  padding-top: 10px;
  margin-top: 15px;
}

@media (max-width: 1250px) and (min-width: 1000px) {  
  .help_subsidy > div > div:nth-child(4) {
    display: none;
  }
}

@media (max-width: 1000px) and (min-width: 650px) {  
  .help_subsidy > div > div:nth-child(4) {
    display: block;
    display: flex;
  }
}

@media (max-width: 650px) and (min-width: 500px) {
  .help_subsidy > div > div:nth-child(4) {
    display: none;
  }
}

.help_subsidy > div > div > div {
  width: 130px;
  height: 1px;
  background-color: #373737;
  margin-top: 10px;
}

.help_subsidy > div > div > p {
  width: 60px;
  font-size: 13px;
  padding: 0 20px 0;
  text-align: left;
  font-weight: bold;
}

.help_subsidy > div > h6 {
  font-size: 16px;
  padding: 5px 20px;
  text-align: center;
  background-color: #222222;
  color: white;
  width: 100px;
  height: 33px;
  line-height: 33px;
  border-radius: 5px;
  margin: 20px auto 0;
}


@media (max-width: 1100px) and (min-width: 1000px) {
}

@media (max-width: 1000px) and (min-width: 900px) {
}

@media (max-width: 900px) and (min-width: 500px) {
}

@media (max-width: 500px) and (min-width: 0px) {
}


