.nav {
  position: fixed;
  display: flex;
  justify-content: space-between;
  margin: 40px 10% 0;
  width: 80%;
  z-index: 10;
}

.nav_icon {
  display: flex;
}

.nav_icon > img {
  width: 33px;
  height: 33px;
  object-fit: contain;
}

.nav_icon > p {
  color: #444444;
  font-size: 15px;
  line-height: 33px;
  padding-left: 20px;
  font-weight: bold;
}

.nav_text {
  width: 300px;
  justify-content: space-between;
  display: flex;
}

.nav_text > a > p {
  color: #444444;
  font-size: 15px;
  line-height: 33px;
  font-weight: bold;
}

@media (max-width: 500px) and (min-width: 0) {
  .nav_icon > p {
    display: none;
  }

  .nav_text {
    width: 150px;
  }

  .nav_text > a:nth-child(1) {
    display: none;
  }
}

