/* @import url('https://fonts.googleapis.com');
@import url('https://fonts.gstatic.com');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap'); */

* {
  margin: 0;
  padding: 0;
  /* font-family: 'Noto Serif JP' */
}

/* コンタクトバー */


.hojokin_contact_message {
  z-index: 100;
  width: 1000px;
  padding: 30px calc((100vw - 1000px) / 2);
  background-color: #19347A;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) and (min-width: 0px) {
  .hojokin_contact_message {
    display: block;
    width: 80vw;
    padding: 30px 10vw;
  }
}

.hojokin_contact_message > h4 {
  text-align: left;
  line-height: 40px;
  font-size: 18px;
  color: white;
}

@media (max-width: 1200px) and (min-width: 500px) {
  .hojokin_contact_message > h4 {
    text-align: center;
  }
}

@media (max-width: 499px) and (min-width: 0px) {
  .hojokin_contact_message > h4 {
    text-align: left;
  }
}

.hojokin_contact_message > a > div {
  width: 150px;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  background-color: white;
  color: #222222;
  text-align: center;
  font-weight: bold;
  border-radius: 3px;
}

@media (max-width: 1200px) and (min-width: 500px) {
  .hojokin_contact_message > a > div {
    margin: 10px auto 0;
  }
}

@media (max-width: 499px) and (min-width: 0px) {
  .hojokin_contact_message > a > div {
    width: 100%;
  }
}
