.media_topic {
  width: 350px;

}

.media_topic_title {
  width: 86%;
  padding: 15px 7%;
  background-color: #234565;
  color: white;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: left;
}

.media_topic_writer {
  width: 85%;
  padding:  0 7.5% 0px;
  background-color: white;
  margin-bottom: 30px;
}

.media_topic_writer > div {
  width: 100%;
  padding: 30px 0 0;
  background-color: white;
  display: flex;
  margin-bottom: 10px;
}

.media_topic_writer > div > img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.media_topic_writer > div > h5 {
  line-height: 30px;
  margin-left: 20px;
  font-size: 13px;
  font-weight: 600;
  color: #222222;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.media_topic_writer > h5 {
  padding: 30px 0 0;
  line-height: 30px;
  margin-left: 0px;
  font-size: 13px;
  font-weight: 600;
  color: #222222;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.media_topic_writer > hr {
  width: 100%;
  height: .5px;
  background-color: #666666;
  border: none;
  color: #666666;
}

.media_topic_writer > p  {
  width: 100%;
  color: white;
  padding: 30px 0;
  color: #222222;
  line-height: 25px;
  font-size: 15px;
  text-align: left;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.media_topic_content {
  width: 86%;
  height: 80px;
  padding: 20px 7%;
  background-color: white;
  margin-bottom: 20px;
  display: flex;
}

.media_topic_content > img {
  width: 130px;
  height: 80px;
  object-fit: cover;
}

.media_topic_content > h5 {
  font-size: 15px;
  color: #222222;
  margin-left: 13px;
  text-align: left;
  line-height: 25px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .media_topic {
    width: 100%;
    margin-top: 50px;
  }
}

.media_topic_table {
  padding: 30px 5%;
  background-color: white;
  max-height: calc(100vh - 380px);
  overflow-y: scroll;
}

.media_topic_table > ol {
  font-size: 15px;
  line-height: 40px;
  color: #464646;
  padding-left: 18px;
}

.media_topic_table > ol > ul {
  font-size: 15px;
  line-height: 35px;
  color: #464646;
  padding-left: 30px;
}

.media_topic_table > ol > li > a {
  text-decoration: none;
  color: #464646;
}

.media_topic_table > ol > ul > li > a {
  text-decoration: none;
  color: #464646;
}

/* 問い合わせ */

.media_topic_sticky {
  position: sticky;
  top: 150px;
}

.media_topic_contact {
  position: sticky;
  top: 230px;
  width: 100%;
  background-color: white;
  padding: 10px 0 30px;
}

.media_topic_name {
  width: 85%;
  margin: 0 7.5%;
}

.media_topic_name > p {
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 15px;
  color: #222222;
  margin: 20px 0 10px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.media_topic_name > input {
  width: calc(100% - 2px);
  border: none;
  border: 1px solid #DDDDDD;
  background-color: white;
  height: 45px;
  line-height: 45px;
  text-indent: 1em;
}

.media_topic_name > textarea {
  width: calc(100% - 2px - 20px);
  border: none;
  border: 1px solid #DDDDDD;
  background-color: white;
  resize: none;
  height: 100px;
  line-height: 30px;
  padding: 10px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.media_topic_contact > div > button {
  width: calc(85% - 2px);
  height: 40px;
  line-height: 40px;
  border-radius: 2px;
  font-size: 15px;
  background-color: #FEF9E7;
  color: #222222;
  border: none;
  margin-top: 20px;
  font-weight: bold;
  border: 1px solid #CF8329;
}

.media_topic_contact > div > img {
  width: 200px;
  margin-top: 30px;
}

.media_topic_contact > div > h6 {
  text-align: center;
  font-size: 17px;
  margin-top: 30px;
  color: #222222;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .media_topic_sticky {
    position: initial;
  }
  
  .media_topic_contact > button {
    position: initial;
  }
}
