.hojokin_nav {
  position: fixed;
  z-index: 1;
  width: 90vw;
  padding: 30px 5vw 20px;
  display: flex;
  justify-content: space-between;
  z-index: 3;
  top: 0;
}

/* ナビゲーション_左 */

.hojokin_nav_left {
  display: flex;
}

@media (max-width: 700px) and (min-width: 500px) {
  .hojokin_nav_left_name {
    display: none;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .hojokin_nav_left_name {
    display: block;
  }
}

.hojokin_nav_left > img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.hojokin_nav_left > h5 {
  color: white;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
  margin-left: 20px;
}

/* ナビゲーション_右 */

.hojokin_nav_right {
  width: 600px;
  display: flex;
  justify-content: space-between;
  color: white;
}

@media (max-width: 1000px) and (min-width: 700px) {
  .hojokin_nav_right {
    width: 400px;
  }
}

@media (max-width: 700px) and (min-width: 500px) {
  .hojokin_nav_right {
    width: 270px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .hojokin_nav_right {
    width: 130px;
  }
}

.hojokin_nav_right > a > h5 {
  color: white;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px;
  margin-left: 20px;
}

.hojokin_nav_right > a > button {
  width: 130px;
  height: 40px;
  line-height: 40px;
  background-color: #19347A;
  color: white;
  border: none;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .hojokin_nav_right_topPage {
    display: none;
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .hojokin_nav_right_blog {
    display: none;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .hojokin_nav_right_company {
    display: none;
  }
}

/*  */

.hojokin_form_bar {
  position: fixed;
  bottom: 0;
  width: 1000px;
  padding: 30px calc((100vw - 1000px) / 2);
  background-color: #19347A;
  z-index: 200;
}

@media (max-width: 1200px) and (min-width: 0px) {
  .hojokin_form_bar {
    display: block;
    width: 80vw;
    padding: 30px 10vw;
  }
}

.hojokin_form_bar > a > div {
  margin: 0 auto;
  width: 500px;
  height: 45px;
  line-height: 45px;
  font-size: 15px;
  background-color: rgb(217, 109, 0);
  color: white;
  text-align: center;
  font-weight: bold;
  border-radius: 3px;
  font-weight: bold;
  border: 1px solid white;
}

@media (max-width: 1200px) and (min-width: 500px) {
  .hojokin_form_bar > a > div {
    margin: 10px auto 0;
  }
}

@media (max-width: 499px) and (min-width: 0px) {
  .hojokin_form_bar > a > div {
    width: 100%;
  }
}






