@media (max-width: 1400px) and (min-width: 0px) {
  .test_homepage_pc {
    display: none;
  }  
}

@media (max-width: 10000px) and (min-width: 1400px) {
  .test_homepage_tablet {
    display: none;
  } 
}

