.bid_list_search_bar {
  width: 850px;
  padding: 80px calc((100% - 850px) / 2) 0;
  height: 80px;
  background-color: #D70A0A;
  display: flex;
  justify-content: space-between;
}

.bid_list_search_bar > p {
  font-size: 13px;
  color: white;
  line-height: 80px;
  font-weight: bold;
}

.bid_list_search_bar > select {
  width: 200px;
  height: 40px;
  border: none;
  background-color: #FAFAFA;
  text-indent: 1em;
  margin: 20px 0;
  border-radius: 0px;
  color: #222222;
}

.bid_list_search_bar > input {
  width: 430px;
  height: 40px;
  border: none;
  background-color: #FAFAFA;
  text-indent: 1em;
  margin: 20px 0;
  border-radius: 0px;
  color: #222222;
}

.bid_list_search_bar > button {
  margin: 20px 0;
  width: 100px;
  height: 40px;
  background-color: white;
  line-height: 40px;
  border: none;
  color: #D70A0A;
  font-weight: bold;
}

/* 850 */

@media (max-width: 850px) and (min-width: 0px) {
  .bid_list_search_bar > p {
    display: none;
  }
}

@media (max-width: 850px) and (min-width: 501px) {
  .bid_list_search_bar {
    width: 500px;
    padding: 80px calc((100% - 500px) / 2) 15px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .bid_list_search_bar > select {
    width: 130px;
    margin: 15px 0 0;
  }

  .bid_list_search_bar > input {
    width: 350px;
    margin: 15px 0 0;
  }

  .bid_list_search_bar > button {
    margin: 15px 0;
    width: 100%;
  }
}

/* 850 */

@media (max-width: 500px) and (min-width: 0px) {
  .bid_list_search_bar {
    width: 80%;
    padding: 80px 10% 15px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
  }

  .bid_list_search_bar > select {
    width: 100%;
    margin: 15px 0 0;
  }

  .bid_list_search_bar > button {
    margin: 15px 0 0;
    width: 100%;
  }

  .bid_list_search_bar > p {
    width: 100%;
    text-align: center;
    line-height: 40px;
  }  
}

/* bid_list_details */

.bid_list_details {
  top: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
}

.bid_list_details_background {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #222222;
  z-index: 11;
  opacity: .2;
}

.bid_list_details_box {
  position: absolute;
  top: 0;
  width: 700px;
  height: 320px;
  background-color: white;
  border-radius: 5px;
  z-index: 12;
  margin: calc((100vh - 360px) / 2) calc((100vw - 750px) / 2);
  padding: 20px 25px;
  border: 1px solid #e2e2e2;
}

.bid_list_details_box_title {
  display: flex;
  justify-content: space-between;
}

.bid_list_details_box_title > h4 {
  color: #222222;
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 40px;
}

.bid_list_details_box_title > button {
  width: 80px;
  height: 40px;
  line-height: 40px;
  border: 0;
  background-color: #D70A0A;
  color: white;
  font-weight: bold;
}

.bid_list_details_box_down_title {
  display: flex;
  justify-content: space-between;
}

.bid_list_details_box_down_title > p {
  width: 200px;
  margin: 30px 0 0 0;
  font-size: 15px;
  color: #222222;
  margin-bottom: 10px;
}

.bid_list_details_box_down_content {
  display: flex;
  justify-content: space-between;
}

.bid_list_details_box_down_content > select {
  width: 200px;
  height: 40px;
  border: none;
  border: 1px solid #EBEBEB;
  margin: 20px 0 0;
  background-color: #fafafa;
}

.bid_list_details_box_down_content > input {
  width: 200px;
  height: 40px;
  border: none;
  border: 1px solid #EBEBEB;
  margin: 20px 0 0;
  background-color: #fafafa;
  -webkit-appearance: none;
  appearance: none;
}

@media (min-width: 750px) {
  .bid_list_details_box_down_mini {
    display: none;
  }
}

/* mini ver */

.bid_list_details_box_down_title_mini {
  display: flex;
  justify-content: space-between;
}

.bid_list_details_box_down_title_mini > p {
  width: 200px;
  margin: 30px 0 0 0;
  font-size: 15px;
  color: #222222;
  margin-bottom: 10px;
}

.bid_list_details_box_down_content_mini {
  display: flex;
  justify-content: space-between;
}

.bid_list_details_box_down_content_mini > select {
  width: 200px;
  height: 40px;
  border: none;
  border: 1px solid #EBEBEB;
  margin: 20px 0 0;
  background-color: #fafafa;
}

.bid_list_details_box_down_content_mini > input {
  width: 200px;
  height: 40px;
  border: none;
  border: 1px solid #EBEBEB;
  margin: 20px 0 0;
  background-color: #fafafa;
}

/* 750 */

@media (max-width: 750px) and (min-width: 0px) {
  .bid_list_details_box {
    width: 450px;
    margin: calc((100vh - 360px) / 2) calc((100vw - 500px) / 2);
    height: auto;
  }
  
  .bid_list_details_box_down {
    display: none;
  }
}

/* 500 */

@media (min-width: 500px) {
  .bid_list_details_box_down_mini_mini {
    display: none;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .bid_list_details_box {
    width: 80%;
    height: auto;
    margin: 120px 5%;
    padding: 20px 5%;
  }
  
  .bid_list_details_box_down_mini {
    display: none;
  }

  .bid_list_details_box_down_mini_mini {
    width: 100%;
    padding: 10px 0;
  }

  .bid_list_details_box_down_mini_mini > p {
    width: 100%;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .bid_list_details_box_down_mini_mini > select {
    width: 100%;
    height: 40px;
    border: none;
    border: 1px solid #EBEBEB;
    margin: 0px 0 0;
    background-color: #fafafa;
  }
  
  .bid_list_details_box_down_mini_mini > input {
    width: 100%;
    height: 40px;
    border: none;
    border: 1px solid #EBEBEB;
    margin: 0px 0 0;
    background-color: #fafafa;
  }
}

