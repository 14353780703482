.help_result {
  width: 100vw;
  border-radius: 50px 50px 0 0;
  border-top: 3px solid  #222222;
  background-color: white;
}

.help_result > h4 {
  width: 100vw;
  font-size: 37px;
  margin-top: 150px;
  text-align: center;
  color: #222222;
}

@media (max-width: 499px) and (min-width: 0) {
  .help_result > h4 {
    width: 100vw;
    font-size: 30px;
    margin-top: 150px;
    text-align: center;
    color: #222222;
  }
}

.help_result_slider {
  width: 1200px;
  height: 300px;
  margin: 100px auto 0;
  padding-bottom: 30px;
}

@media (max-width: 1300px) and (min-width: 1000px) {
  .help_result_slider {
    width: 1000px;
    margin: 100px auto 0;
  }
}

@media (max-width: 1000px) and (min-width: 700px) {
  .help_result_slider {
    width: 650px;
    margin: 100px auto 0;
  }
}

@media (max-width: 700px) and (min-width: 500px) {
  .help_result_slider {
    width: 300px;
    margin: 100px auto 0;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_result_slider {
    width: 300px;
    margin: 100px auto 0;
  }
}

.help_result_itme {
  position: relative;
  width: 250px;
  height: 250px;
}

.help_result_img {
  position: absolute;
  width: 250px;
  height: 250px;
  object-fit: cover;
  margin: 0 25px;
}

.help_result_itme > p {
  position: absolute;
  width: 220px;
  z-index: 1;
  margin: 15px 35px 0;
  color: #222222;
  font-size: 13px;
  text-align: left;
}

.help_result_itme > p > span {
  line-height: 30px;
}

