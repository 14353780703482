.test_homepage_nav {
  position: fixed;
  top: 0;
  width: 1200px;
  margin: 30px calc((100vw - 1200px) / 2) 0;
  display: flex;
  justify-content: space-between;
  z-index: 100;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .test_homepage_nav {
    width: 960px;
    margin: 30px calc((100vw - 960px) / 2) 0;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .test_homepage_nav {
    width: 740px;
    margin: 30px calc((100vw - 740px) / 2) 0;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .test_homepage_nav {
    width: 540px;
    margin: 30px calc((100vw - 540px) / 2) 0;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .test_homepage_nav {
    width: 90vw;
    margin: 30px 5vw 0;
  }
}

.test_homepage_nav_left {
  width: 220px;
  display: flex;
}

.test_homepage_nav_left > img {
  width: 50px;
  height: 50px;
}

.test_homepage_nav_left > div {
  width: 150px;
}

.test_homepage_nav_left > div > p {
  width: 150px;
  font-size: 13px;
  padding-left: 10px;
}

.test_homepage_nav_left > div > h4 {
  width: 150px;
  font-size: 18px;
  padding-left: 8px;
}

.test_homepage_nav_right {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

@media (max-width: 600px) and (min-width: 0px) {
  .test_homepage_nav_right {
    width: 180px;
  }
}

.test_homepage_nav_right > a > p {
  font-size: 15px;
  font-weight: bold;
  line-height: 60px;
  color: #222222;
}

