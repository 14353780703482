.api_header_bar {
  position: fixed;
  width: 100%;
  height: 10px;
  background-color: #1F2C5B;
}

/* top */

.api_header_top {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  padding: 70px calc((100% - 1200px) / 2) 0;
}

.api_header_top > h2 {
  font-size: 23px;
  color: #222222;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  line-height: 41px;
}

.api_header_top > div {
  width: 450px;
  display: flex;
  justify-content: space-between;
}

.api_header_top > div > a > button {
  border: none;
  width: 130px;
  height: 40px;
  line-height: 40px;
  color: white;
  font-size: 13px;
  border: .5px solid #222222;
  border-radius: 2px;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  background-color: white;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .api_header_top {
    width: 1000px;
    padding: 70px calc((100% - 1000px) / 2) 0;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .api_header_top {
    width: 800px;
    padding: 70px calc((100% - 800px) / 2) 0;
  }
}

@media (max-width: 800px) and (min-width: 0px) {
  .api_header_top {
    width: 90%;
    padding: 70px calc((100% - 90%) / 2) 0;
  }

  .api_header_top > div {
    width: 130px;
  }

  .api_header_top_500 {
    display: none;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .api_header_top > h2 {
    font-size: 20px;
  }
}

/* left */

.api_header_left {
  position: fixed;
  top: 200px;
  left: calc((100% - 1200px) / 2);
  width: 25px;
}

.api_header_left > div {
  width: 10px;
  height: 10px;
  background-color: #E29150;
  margin: 50px 7px 0;
  border-radius: 100px;
}

.api_header_left > h3 {
  width: 25px;
  text-align: center;
  line-height: 19px;
  font-size: 15px;
  margin-top: 20px;
  color: #222222;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

/* 全体 */

.api {
  width: 100%;
  background-color: #F4EFEB;
  padding-bottom: 50px;
}

.api_media {
  width: 700px;
  padding-top: 150px;
  margin: 0 auto;
}

@media (max-width: 700px) and (min-width: 0px) {
  .api_media {
    width: 90%;
  }
}

.api_media > h2 {
  color: #222222;
  font-size: 30px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.api_media > div {
  display: flex;
  margin: 50px 0 30px;
}

.api_media > div > p {
  width: 20px;
  height: 20px;
  color: white;
  text-align: center;
  font-size: 10px;
  border-radius: 100px;
  font-weight: 400;
  line-height: 20px;
  margin: 5px 0;
}

.api_media > div > h3 {
  font-size: 18px;
  color: #222222;
  line-height: 30px;
  margin-left: 20px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.api_media > p {
  font-size: 16px;
  line-height: 30px;
  color: #222222;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  margin-bottom: 100px;
  white-space: pre-line;
}

.api_media_table {
  width: 100%;
  border: 1px solid #222222;
  border-collapse: collapse;
  margin-bottom: 100px;
}

.api_media_table > * p {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.api_media_q {
  width: 20%;
  background-color: #EB6231;
  color: white;
  border: 1px solid #222222;
  font-weight: bold;
}

.api_media_a {
  width: 80%;
  background-color: white;
  color: #222222;
  text-align: left;
  border: 1px solid #222222;
}

.api_media > a > button {
  border: none;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  line-height: 40px;
  color: #222222;
  background-color: #EB6231;
  color: white;
  border-radius: 2px;
  border: 0.5px solid #222222;
  margin: 0 0 50px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

/* api_media_blog */

.api_media_blog {
  width: 100vw;
  margin-top: 100px;
  font-size: 28px;
  color: #222222;
  text-align: center;
}

