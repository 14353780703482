.hojokin_contact {
  width: 100vw;
  height: 950px;
  display: flex;
  color: #222222;
}

.hojokin_contact_form {
  width: 100vw;
  height: 950px;
  background-color: #19347A;
}

.hojokin_contact_form > div {
  width: 450px;
  height: 760px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #222222;
  margin: 100px auto 150px;
  text-align: center;
}

@media (max-width: 450px) and (min-width: 0px) {
  .hojokin_contact_form > div {
    width: 350px;
  }
}

.hojokin_contact_form > div > h4 {
  font-size: 18px;
  margin: 40px auto 10px;
}

.hojokin_contact_form > div > p {
  width: 330px;
  font-size: 12px;
  margin: 0 auto 30px;
  color: #373737;
}

@media (max-width: 450px) and (min-width: 0px) {
  .hojokin_contact_form > div > p {
    width: 300px;
  }
}

.hojokin_contact_form_input {
  width: 330px;
  text-align: left;
  margin: 0 60px 10px;
  background-color: white;
}

@media (max-width: 450px) and (min-width: 0px) {
  .hojokin_contact_form_input {
    width: 300px;
    margin: 0 25px 10px ;
  }
}

.hojokin_contact_form_input > h5 {
  width: 330px;
  font-size: 13px;
  line-height: 30px;
}

@media (max-width: 450px) and (min-width: 0px) {
  .hojokin_contact_form_input > h5 {
    width: 300px;
  }
}

.hojokin_contact_form_input > input {
  width: 330px;
  border: none;
  height: 35px;
  background-color: white;
}

@media (max-width: 450px) and (min-width: 0px) {
  .hojokin_contact_form_input > input {
    width: 300px;
  }
}

.hojokin_contact_form_input > div {
  width: 330px;
  height: .5px;
  background-color: #222222;
}

@media (max-width: 450px) and (min-width: 0px) {
  .hojokin_contact_form_input > div {
    width: 300px;
  }
}

.hojokin_contact_form_input > p {
  font-size: 10px;
  color: red;
}

.hojokin_contact_form_context {
  width: 330px;
  text-align: left;
  margin: 0 60px 10px;
  background-color: white;
}

@media (max-width: 450px) and (min-width: 0px) {
  .hojokin_contact_form_context {
    width: 300px;
    margin: 0 25px 10px ;
  }
}

.hojokin_contact_form_context > h5 {
  width: 330px;
  font-size: 13px;
  line-height: 30px;
}

@media (max-width: 450px) and (min-width: 0px) {
  .hojokin_contact_form_context > h5 {
    width: 300px;
  }
}

.hojokin_contact_form_context > textarea {
  width: 330px;
  border: none;
  height: 100px;
  background-color: white;
  resize: none;
}

@media (max-width: 450px) and (min-width: 0px) {
  .hojokin_contact_form_context > textarea {
    width: 300px;
  }
}

.hojokin_contact_form_context > div {
  width: 330px;
  height: .5px;
  background-color: #222222;
}

@media (max-width: 450px) and (min-width: 0px) {
  .hojokin_contact_form_context > div {
    width: 300px;
  }
}

.hojokin_contact_form_context > p {
  font-size: 10px;
  color: red;
}

.hojokin_contact_form_btn {
  width: 150px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #222222;
  margin: 30px auto 0;
}

.hojokin_contact_form_btn:hover {
  border: 1px solid #222222;
  background-color: #222222;
  color: white;
}



