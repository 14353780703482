/* subsidy_potal_case */

.subsidy_potal_list_case > div {
  width: 950px;
  margin: 0px calc((100vw - 1000px) / 2);
  padding: 25px 25px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  border: 1px solid #1F2C5B;
}

.subsidy_potal_list_case > div > img {
  margin-bottom: 0;
}

@media (max-width: 1000px) and (min-width: 801px) {
  .subsidy_potal_list_case > div {
    width: 750px;
    margin: 0px calc((100vw - 800px) / 2) 50px;
  }
}

@media (max-width: 800px) and (min-width: 601px) {
  .subsidy_potal_list_case > div {
    width: 550px;
    margin: 0px calc((100vw - 600px) / 2) 50px;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .subsidy_potal_list_case > div {
    width: calc(90% - 50px);
    margin: 0px 5% 50px;
  }
}

.subsidy_potal_list_case > div > img {
  width: 80px;
  height: 80px;
  border-radius: 300px;
  padding: 20px;
  border: 1px solid #1F2C5B;
  object-fit: cover;
}

@media (max-width: 600px) and (min-width: 0px) {
  .subsidy_potal_list_case > div > img {
    display: none;
  }
}

.subsidy_potal_list_case > div > div {
  width: 80%;
}

@media (max-width: 800px) and (min-width: 601px) {
  .subsidy_potal_list_case > div > div {
    width: calc(100% - 200px);
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .subsidy_potal_list_case > div > div {
    width: calc(100% - 50px);
  }
}

.subsidy_potal_list_case_date {
  font-size: 13px;
  color: #222222;
  margin: 10px 0 10px;
}

.subsidy_potal_list_case > div > div > h3 {
  font-size: 20px;
  color: #222222;
  margin: 0 0 10px;
  line-height: 40px;
}

.subsidy_potal_list_case > div > div > h6 {
  font-size: 13px;
  color: #222222;
  margin-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
}

.subsidy_potal_list_case_border {
  height: 3px;
  background-color: #222222;
  width: calc(70% - 55px);
  margin: 0px 15%;
}

.subsidy_potal_list_case > div > h4 {
  height: 120px;
  line-height: 120px;
  color: #222222;
}

/* subsidy_potal_list_background */

.subsidy_potal_list_background {
  margin: auto 0;
  padding: 50px 0;
  background-color: #F4EFEB;
}
