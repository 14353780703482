/* 全体 */
.simulator_header {
  height: 450px;
  width: 100vw;
  z-index: 1;
  background-image: url(./../../../../../image/simulator/head.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.simulator_header {
  text-align: center;
}

.simulator_header > h2 {
  font-size: 30px;
  color: white;
  padding-top: 100px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 700px) and (min-width: 0px) {
  .simulator_header > h2 {
    font-size: 26px;
  }
}

.simulator_header > h3 {
  font-size: 30px;
 color: #e8eef8;
 font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 700px) and (min-width: 499px) {
  .simulator_header > h3 {
    font-size: 24px;
  }
}

@media (max-width: 499px) and (min-width: 0px) {
  .simulator_header > h3 {
    font-size: 18px;
  }
}

.simulator_header > p {
  font-size: 13px;
  color: white;
  margin-top: 30px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 499px) and (min-width: 0px) {
  .simulator_header > p {
    font-size: 13px;
    color: white;
    margin: 30px 10px 0;
  }

  .simulator_header > p > br {
    display: none;
  }
}

.simulator_header_btn {
  display: flex;
  justify-content: space-between;
  width: 330px;
  margin: 30px auto 0;
}

.simulator_header_btn1 {
  width: 150px;
  text-align: center;
  line-height: 40px;
  font-size: 13px;
  color: #222222;
  border: none;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  background-color: white;
  font-weight: bold;
  border-radius: 3px;
}

.simulator_header_btn2 {
  width: 150px;
  text-align: center;
  line-height: 40px;
  font-size: 13px;
  color: #EB6231;
  background-color: white;
  font-weight: bold;
  border-radius: 3px;
  border: none;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}


