.AdBusiness_bottom {
  width: 100vw;
  height: 800px;
  display: flex;
  color: #222222;
}

.AdBusiness_bottom_form {
  width: 100vw;
  height: 800px;
  /* background-color: #FADF4B; */
}

.AdBusiness_bottom_form > div {
  width: 450px;
  height: 600px;
  background-color: #F4F2EB;
  border-radius: 5px;
  border: 1px solid #222222;
  margin: 100px auto;
  text-align: center;
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_bottom_form > div {
    width: 350px;
  }
}

.AdBusiness_bottom_form > div > h4 {
  font-size: 18px;
  margin: 40px auto 10px;
}

.AdBusiness_bottom_form > div > p {
  width: 330px;
  font-size: 12px;
  margin: 0 auto 30px;
  color: #373737;
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_bottom_form > div > p {
    width: 300px;
  }
}

.AdBusiness_bottom_form_input {
  width: 330px;
  text-align: left;
  margin: 0 60px 10px;
  background-color: #F4F2EB;
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_bottom_form_input {
    margin: 0 30px 10px;
  }
}

.AdBusiness_bottom_form_input > h5 {
  width: 330px;
  font-size: 13px;
  line-height: 30px;
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_bottom_form_input > h5 {
    width: 300px;
  }
}

.AdBusiness_bottom_form_input > input {
  width: 330px;
  border: none;
  height: 35px;
  background-color: #F4F2EB;
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_bottom_form_input > input {
    width: 300px;
  }
}

.AdBusiness_bottom_form_input > div {
  width: 330px;
  height: .5px;
  background-color: #222222;
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_bottom_form_input > div {
    width: 300px;
  }
}

.AdBusiness_bottom_form_input > p {
  font-size: 10px;
  color: red;
}

.AdBusiness_bottom_form_btn {
  width: 150px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #222222;
  margin: 30px auto 0;
}

.AdBusiness_bottom_form_btn:hover {
  border: 1px solid #222222;
  background-color: #222222;
  color: white;
}

