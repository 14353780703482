.AdBusiness_what_box1 {
  width: 1000px;
  background-color: white;
  box-shadow: 0px 0px 5px rgb(205, 205, 205);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .AdBusiness_what_box1 {
    width: 900px;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_what_box1 {
    width: 750px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .AdBusiness_what_box1 {
    width: 500px;
    display: block;
  }
}

@media (max-width: 600px) and (min-width: 450px) {
  .AdBusiness_what_box1 {
    width: 450px;
    display: block;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_what_box1 {
    width: 350px;
    display: block;
  }
}

.AdBusiness_what_box1 > div {
  width: 450px;
  padding: 80px;
}

@media (max-width: 1200px) and (min-width: 600px) {
  .AdBusiness_what_box1 > div {
    padding: 50px;
    width: 400px;
  }
}

@media (max-width: 1200px) and (min-width: 600px) {
  .AdBusiness_what_box1 > div {
    padding: 25px;
    width: 400px;
  }
}

@media (max-width: 600px) and (min-width: 450px) {
  .AdBusiness_what_box1 > div {
    padding: 50px;
    width: 350px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_what_box1 > div {
    padding: 25px;
    width: 300px;
  }
}

.AdBusiness_what_box1 > div > h4 {
  font-size: 18px;
  color: #222222;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_what_box1 > div > h4 {
    font-size: 15px;
  }
}

@media (max-width: 600px) and (min-width: 450px) {
  .AdBusiness_what_box1 > div > h4 {
    font-size: 13px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_what_box1 > div > h4 {
    font-size: 15px;
  }
}

.AdBusiness_what_box1 > div > h3 {
  font-size: 30px;
  color: #222222;
  padding: 10px 0 0 0;
  line-height: 50px;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_what_box1 > div > h3 {
    font-size: 26px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .AdBusiness_what_box1 > div > h3 {
    font-size: 30px;
  }
}

@media (max-width: 600px) and (min-width: 450px) {
  .AdBusiness_what_box1 > div > h3 {
    font-size: 27px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_what_box1 > div > h3 {
    font-size: 23px;
  }
}

.AdBusiness_what_box1 > div > p {
  font-size: 15px;
  color: #222222;
  line-height: 30px;
  padding: 30px 0 0 0;
}

@media (max-width: 600px) and (min-width: 450px) {
  .AdBusiness_what_box1 > div > p {
    font-size: 13px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_what_box1 > div > p {
    font-size: 13px;
  }
}

.AdBusiness_what_box1 > img {
  width: 330px;
  height: 330px;
  object-fit: contain;
  padding: 50px 50px 50px 0;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .AdBusiness_what_box1 > img {
    width: 260px;
    height: 260px;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_what_box1 > img {
    width: 260px;
    height: 260px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .AdBusiness_what_box1 > img {
    width: 400px;
    height: 400px;
    padding: 0 50px 20px 50px;
  }
}

@media (max-width: 600px) and (min-width: 450px) {
  .AdBusiness_what_box1 > img {
    width: 350px;
    height: 350px;
    padding: 0 50px 20px 50px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_what_box1 > img {
    width: 300px;
    height: 300px;
    padding: 0 25px 20px 25px;
  }
}



