.photo_fixed_company {
  position: fixed;
  margin: 100px 0 0 calc(100vw - 42px);
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  width: 20px;
  padding: 15px 10px 15px 13px;
  border-radius: 5px 0 0 5px;
  font-size: 15px;
  background-color: #FF8D89;
  color: white;
}

.photo_fixed_photoList {
  position: fixed;
  margin: 290px 0 0 calc(100vw - 42px);
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  width: 20px;
  padding: 15px 10px 15px 13px;
  border-radius: 5px 0 0 5px;
  font-size: 15px;
  background-color: #FF8D89;
  color: white;
}

.photo_fixed_download {
  position: fixed;
  margin: 450px 0 0 calc(100vw - 42px);
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  width: 20px;
  padding: 15px 10px 15px 13px;
  border-radius: 5px 0 0 5px;
  font-size: 15px;
  background-color: #FF8D89;
  color: white;
  text-align: center;
}

@media (max-width: 500px) and (min-width: 0) {
  .photo_fixed_company {
    display: none;
  }
  
  .photo_fixed_photoList {
    display: none;
  }
  
  .photo_fixed_download {
    display: none;
  }
}



.photo_header {
  padding: 130px 0 0;
  text-align: center;
}

.photo_header > h5 {
  color: #FF8D89;
  font-size: 15px;
  text-align: center;
}

.photo_header > h4 {
  color: #FF8D89;
  font-size: 45px;
  text-align: center;
}

.photo_header > p {
  position: absolute;
  width: 89vw;
  margin: -50px 5.5vw 0;
  text-align: right;
  color: #FF8D89;
  font-size: 13px;
  margin-left: auto;
  font-weight: bold;
}

/* ================== */

.photo_list_title {
  width: 200px;
  line-height: 40px;
  background-color: #FF8D89;
  color: white;
  font-weight: bold;
  border-radius: 100px;
  margin: 60px auto 0;
  text-align: center;
}

.photo_list_box {
  width: 80vw;
  padding: 30px 10vw 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.photo_list_box_items {
  width:  170px;
  height: 170px;
  margin: 20px 15px;
}

.photo_list_box_items > img {
  width:  170px;
  height: 170px;
  object-fit: cover;
  border-radius: 5px;
}


@media (max-width: 1300px) and (min-width: 1000px) {
  .photo_list_box {
    width: 95vw;
    padding: 30px 2.5vw 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .photo_list_box_items {
    width:  170px;
    height: 170px;
    margin: 20px 10px;
  }
}

@media (max-width: 1000px) and (min-width: 850px) {
  .photo_list_box {
    width: 100vw;
    padding: 30px 0vw 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .photo_list_box_items {
    width:  140px;
    height: 140px;
    margin: 20px 15px;
  }

  .photo_list_box_items > img {
    width:  140px;
    height: 140px;
    object-fit: cover;
    border-radius: 5px;
  }
}

@media (max-width: 850px) and (min-width: 700px) {
  .photo_list_box {
    width: 100vw;
    padding: 30px 0vw 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .photo_list_box_items {
    width:  140px;
    height: 140px;
    margin: 20px 15px;
  }

  .photo_list_box_items > img {
    width:  140px;
    height: 140px;
    object-fit: cover;
    border-radius: 5px;
  }
}

@media (max-width: 450px)  {
  .photo_list_box {
    width: 90vw;
    padding: 30px 5vw 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .photo_list_box_items {
    width:  140px;
    height: 140px;
    margin: 20px 10px;
  }

  .photo_list_box_items > img {
    width:  140px;
    height: 140px;
    object-fit: cover;
    border-radius: 5px;
  }
}

.Photo_student_name {
  width: 100vw;
  height: 100px;
  background-image: url(./../../../../image/photo/header.png);
  background-repeat: repeat-x;
  background-size: auto 90px;
  animation: loop 20s linear infinite;
  margin-top: 100px;
  /* padding: 5px 0; */
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}

@keyframes loop {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -2400px 0;
  }
}


