

.bussiness {
  width: 85%;
  padding: 170px 7.5% 100px;
  display: flex;
  justify-content: space-between;
  background-color: #fafafa;
}

.bussiness > * {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 1300px) and (min-width: 1000px) {
  .bussiness {
    width: 90%;
    padding: 170px 5% 100px;
  }
}

@media (max-width: 1100px) and (min-width: 450px) {
  .bussiness {
    width: 90%;
    padding: 170px 5% 100px;
    display: block;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .bussiness {
    width: 100%;
    padding: 100px 0 100px;
    display: block;
  }
}

.bussiness_contents {
  position: sticky;
  top: 150px;
  top: 170px;
  height: calc(100vh - 270px);
  background-color: white;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media (max-width: 1100px) and (min-width: 0px) {
  .bussiness_contents {
    display: none;
  }
}

.bussiness_contents::-webkit-scrollbar {  
  display: none;
}

.bussiness_contents {
  padding-bottom: 50px;
}

.bussiness_contents > div > h2 {
  width: 250px;
  color: #234566;
  margin: 30px 50px 0;
  font-size: 18px;
  text-align: left;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.bussiness_contents > div > a > h3 {
  width: 240px;
  color: #464646;
  margin: 20px 50px 0;
  font-size: 15px;
  text-align: left;
  padding-left: 10px;
  font-weight: 600;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.bussiness_items {
  width: 720px;
}

.bussiness_items_title > h2 {
  width: calc(100% - 30px);
  height: 55px;
  background-color: #234566;
  color: white;
  text-align: left;
  font-size: 15px;
  line-height: 55px;
  padding-left: 30px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.bussiness_items_title_speace {
  margin-top: 30px;
}

.bussiness_items_item {
  width: calc(100% - 60px);
  display: flex;
  background-color: white;
  margin-top: 40px;
  padding: 20px 30px;
  text-align: left;
}

.bussiness_items_item > img {
  width: 220px;
  height: 130px;
  object-fit: cover;
}

.bussiness_items_item > div > h3 {
  width: calc(720px - 290px);
  font-size: 15px;
  padding: 10px 0 0 20px;
  line-height: 40px;
  color: #464646;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.bussiness_items_item > div > p {
  width: calc(720px - 290px);
  width: auto;
  padding-left: 20px;
  font-size: 15px;
  line-height: 38px;
  color: #464646;
  text-align: left;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}


@media (max-width: 1350px) and (min-width: 1200px) {
  .bussiness_items {
    width: 650px;
  }

  .bussiness_items_item > div > h3 {
    width: calc(650px - 290px);
  }
  
  .bussiness_items_item > div > p {
    width: calc(650px - 290px);
  }
}

@media (max-width: 1200px) and (min-width: 1150px) {
  .bussiness_items {
    width: 650px;
  }

  .bussiness_items_item > div > h3 {
    width: calc(650px - 290px);
  }
  
  .bussiness_items_item > div > p {
    width: calc(650px - 290px);
  }
}

@media (max-width: 1150px) and (min-width: 1100px) {
  .bussiness_items {
    width: 600px;
  }

  .bussiness_items_item > div > h3 {
    width: calc(600px - 290px);
  }
  
  .bussiness_items_item > div > p {
    width: calc(600px - 290px);
  }
}

@media (max-width: 1100px) and (min-width: 700px) {
  .bussiness_items {
    width: 100%;
  }

  .bussiness_items_item > div > h3 {
    width: calc(90vw - 290px);
  }
  
  .bussiness_items_item > div > p {
    width: calc(90vw - 290px);
  }
}

@media (max-width: 700px) and (min-width: 450px) {
  .bussiness_items {
    width: 100%;
  }

  .bussiness_items_item {
    display: block;
  }

  .bussiness_items_item > img {
    width: calc(90vw - 60px);
    height: 280px;
    object-fit: cover;
  }

  .bussiness_items_item > div > h3 {
    width: calc(90vw - 60px);
    padding: 10px 0 0 0px;
  }
  
  .bussiness_items_item > div > p {
    width: calc(90vw - 60px);
    padding: 5px 0 0 0px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .bussiness_items {
    width: 100%;
  }

  .bussiness_items_item {
    width: calc(100vw - 60px);
    display: block;
  }

  .bussiness_items_item > img {
    width: calc(100vw - 60px);
    height: 180px;
    object-fit: cover;
  }

  .bussiness_items_item > div > h3 {
    padding: 10px 0 0 0px;
    width: calc(100vw - 60px);
  }
  
  .bussiness_items_item > div > p {
    padding: 5px 0 0 0px;
    width: calc(100vw - 60px);
  }
}

.business_media_speace {
  height: 200px;
}

.business_media_title {
  width: 1100px;
  height: 50px;
  line-height: 50px;
  margin: 0 calc((100vw - 1100px ) /  2 );
  display: flex;
  border-bottom: 1px solid #222222;
}

@media (max-width: 1100px) and (min-width: 0px) {
  .business_media_title {
    width: 90%;
    height: 50px;
    line-height: 50px;
    margin: 0 5%;
    display: flex;
    border-bottom: 1px solid #222222;
  }
}

.business_media_title > div {
  width: 5px;
  height: 30px;
  background-color: #222222;
  margin: 0 20px 20px 0;
}

.business_media_title > h3 {
  line-height: 30px;
  margin-bottom: 20px;
  color: #222222;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.business_media_box {
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  width: 1100px;
  margin: 100px calc((100vw - 1100px ) /  2 );
}

@media (max-width: 1100px) and (min-width: 700px) {
  .business_media_box {
    width: 700px;
    margin: 100px calc((100vw - 700px) / 2);
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .business_media_box {
    width: 330px;
    margin: 100px calc((100vw - 330px) / 2);
  }
}

.business_media_box > a {
  width: 330px;
  height: 380px;
}

.business_media_box > a > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #222222;
  /* background-image: url(./../../../image/media/1.png); */
  background-size: cover;
}

.business_media_box > a > div > p {
  width: 100px;
  height: 20px;
  font-size: 10px;
  background-color: #EE7F54;
  border-radius: 2px;
  text-align: center;
  font-weight: bold;
  line-height: 20px;
  color: white;
  margin: 7px  0 0 223px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.business_media_box > a > h3 {
  width: 300px;
  color: #222222;
  font-size: 17px;
  margin: 15px 15px 0;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.business_media_box > a > h4 {
  width: 300px;
  color: #555555;
  font-size: 12px;
  margin: 5px 15px;
  font-weight: 400;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.business_media_box > a > p {
  width: 300px;
  color: #555555;
  font-size: 12px;
  margin: 10px 15px 0;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}


