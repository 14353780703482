* > p, * > h1, * > h2, * > h3, * > h4, * > h5, * > h6, * > button, * > input, * > select, * > a, * > div {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.search_speace {
  height: 0;
}

@media (max-width: 850px) and (min-width: 0px) {
  .search_speace {
    height: 100px;
  }
}

/* bid_strong_title */

.bid_strong_title {
  width: 1000px;
  text-align: left;
  font-size: 28px;
  color: #222222;
  margin: 150px calc((100vw - 1000px) / 2) 0;
  display: flex;
}

@media (max-width: 1000px) and (min-width: 801px) {
  .bid_strong_title {
    width: 800px;
    margin: 150px calc((100vw - 800px) / 2) 0;
  }
}

@media (max-width: 800px) and (min-width: 0px) {
  .bid_strong_title {
    display: block;
    width: 400px;
    margin: 150px calc((100vw - 400px) / 2) 0;
    text-align: center;
  }
}

.bid_strong_title > span {
  font-weight: bold;
  color: #D70A0A;
  font-size: 80px;
  line-height: 50px;
}

.bid_strong_title > p {
  font-weight: bold;
  color: #222222;
  font-size: 30px;
  line-height: 50px;
  margin-left: 10px;
}

.bid_strong_items {
  width: 70%;
  margin: 50px 15% 0;
  display: flex;
  justify-content: space-between;
}

.bid_strong_items > div {
  width: 300px;
  height: 350px;
  background-color: #FAFAFA;
}

.bid_strong_items > div > img {
  width: 130px;
  height: 130px;
  margin: 10px calc((300px - 130px) / 2);
  object-fit: contain;
}

.bid_strong_items > div > h4 {
  width: 250px;
  margin: 10px calc((300px - 250px) / 2);
  object-fit: contain;
  text-align: center;
  line-height: 25px;
}

.bid_strong_items > div > p {
  width: 250px;
  margin: 15px calc((300px - 250px) / 2);
  object-fit: contain;
  text-align: left;
  font-size: 13px;
  color: #373737;
}

/* background */

.bid_background {
  height: 1000px;
}

/* header */

.bid_header {
  width: 100%;
  height: 500px;
}


.bid_header_slider_postion {
  position: relative;
  padding-top: 80px;
}

.bid_header_slider_postion_ad {
  position: absolute;
  width: 350px;
  height: 250px;
  background-color: white;
  top: calc(100px + 80px);
  right: 15%;
  border: 3px solid #D70A0A;
  border-radius: 7px;
  box-shadow: 0 0 2px grey;
  background-image: url(./../../.../../../../../image/bid/header/banner.jpg);
  background-size: cover;
}

@media (max-width: 1200px) and (min-width: 0px) {
  .bid_header_slider_postion_ad {
    display: none
  }
}


/* search */

.bid_search {
  width: 70%;
  margin: 80px 15%;
  box-shadow: 0 0 2px #d5d5d5;
  border-radius: 5px;
  padding-bottom: 20px;
  background-color: white;
}

.bid_search_title {
  display: flex;
  width: 90%;
  padding: 20px 5%;
  justify-content: space-between;
}

.bid_search_title > h4 {
  line-height: 40px;
}

.bid_search_title > button {
  width: 100px;
  height: 40px;
  line-height: 40px;
  background-color: #222222;
  color: white;
  border: none;
}

.bid_search > p {
  width: 90%;
  padding: 20px 5%;
  font-size: 15px;
  color: #222222;
}

.bid_search_prefectures {
  width: 90%;
  padding: 0px 5%;
  display: flex;
  flex-wrap: wrap;
}

.bid_search_prefectures > div {
  display: flex;
  height: 40px;
  padding-right: 40px;
}

.bid_search_prefectures > div > input {
  width: 10px;
  height: 10px;
  color: #222222;
  margin: 15px 0;
}

.bid_search_prefectures > div > p {
  height: 40px;
  line-height: 40px;
  margin-left: 13px;
  color: #222222;
}

/* recent */

.bid_recent_web {
  width: 70%;
  margin: 80px 15%;
  font-size: 25px;
  text-align: center;
  color: #222222;
}

.bid_recent_box {
  width: 70%;
  margin: 80px 15%;
}

.bid_recent_box_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bid_recent_box_top > img {
  width: 330px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}

.bid_recent_box_bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.bid_recent_box_bottom > img {
  width: 250px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

/* footer */

.bid_footer {
  width: 100%;
  height: 300px;
  background-color: #1D4F43;
}
