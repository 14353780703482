.test_homeimage_mvvc {
  width: 1200px;
  margin: 100px calc((100vw - 1200px) / 2) 0;
  display: flex;
  justify-content: space-between;
}


@media (max-width: 1200px) and (min-width: 1000px) {
  .test_homeimage_mvvc {
    width: 960px;
    margin: 100px calc((100vw - 960px) / 2) 0;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .test_homeimage_mvvc {
    width: 740px;
    margin: 100px calc((100vw - 740px) / 2) 0;
    display: block;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .test_homeimage_mvvc {
    width: 540px;
    margin: 100px calc((100vw - 540px) / 2) 0;
    display: block;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .test_homeimage_mvvc {
    width: 90vw;
    margin: 100px 5vw 0;
    display: block;
  }
}

.test_homeimage_mvvc_left {
  width: 400px;
  height: 450px;
  background-image: url(./../../../image/homepage/mvvc.png);
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .test_homeimage_mvvc_left {
    width: 300px;
    height: 350px;
    margin: 100px calc((740px - 300px) / 2) 0;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .test_homeimage_mvvc_left {
    width: 300px;
    height: 350px;
    margin: 100px calc((540px - 300px) / 2) 0;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .test_homeimage_mvvc_left {
    width: 300px;
    height: 350px;
    margin: 100px calc((100% - 300px) / 2) 0;
  }
}

.test_homeimage_mvvc_right > div {
  width: 650px;
  /* background-color: #dbeded; */
  margin: 30px 0;
  padding: 30px;
  border: 1px solid #222222;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .test_homeimage_mvvc_right > div {
    width: 450px;
  }
}

@media (max-width: 1000px) and (min-width: 0px) {
  .test_homeimage_mvvc_right > div {
    width: calc(100% - 60px);
  }
}

.test_homeimage_mvvc_right > div > p {
  font-size: 13px;
  color: #111111;
  line-height: 30px;
}

.test_homeimage_mvvc_right > div > h4 {
  font-size: 15px;
  color: #111111;
  line-height: 27px;
}