.subsidy_media_list_search_bar {
  width: 850px;
  padding: 0 75px;
  margin: 0px calc((100% - 1000px) / 2) 0;
  height: 80px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 2px rgb(233, 233, 233);
}

@media (max-width: 1000px) and (min-width: 801px) {
  .subsidy_media_list_search_bar {
    width: 750px;
    padding: 0 25px;
    margin: 0px calc((100% - 800px) / 2) 0;
  }
}

@media (max-width: 801px) and (min-width: 601px) {
  .subsidy_media_list_search_bar {
    width: 550px;
    height: auto;
    padding: 0 25px;
    margin: 0px calc((100% - 600px) / 2) 0;
  }
}

@media (max-width: 601px) and (min-width: 0) {
  .subsidy_media_list_search_bar {
    width: calc(90% - 50px);
    display: block;
    height: auto;
    padding: 0 25px;
    margin: 0px 5% 0;
  }
}

.subsidy_media_list_search_bar > p {
  font-size: 13px;
  color: white;
  line-height: 80px;
  font-weight: bold;
  color: #EC6104;
}

@media (max-width: 601px) and (min-width: 0) {
  .subsidy_media_list_search_bar > p {
    width: 100%;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    padding-top: 20px;
  }
}

.subsidy_media_list_search_bar > select {
  width: 200px;
  height: 40px;
  border: none;
  text-indent: 1em;
  margin: 20px 0;
  border-radius: 0px;
  color: #222222;
  background-color: white;
  border: 1px solid #EC6104;
}

@media (max-width: 1000px) and (min-width: 801px) {
  .subsidy_media_list_search_bar > select {
    width: 180px;
  }
}

@media (max-width: 801px) and (min-width: 601px) {
  .subsidy_media_list_search_bar > select {
    width: 130px;
  }
}

@media (max-width: 601px) and (min-width: 0) {
  .subsidy_media_list_search_bar > select {
    width: 100%;
    margin: 10px  0;
  }
}

.subsidy_media_list_search_bar > input {
  width: 400px;
  height: 38px;
  border: none;
  text-indent: 1em;
  margin: 20px 0;
  border-radius: 0px;
  color: #222222;
  background-color: white;
  border: 1px solid #EC6104;
}

@media (max-width: 1000px) and (min-width: 801px) {
  .subsidy_media_list_search_bar > input {
    width: 350px;
  }
}

@media (max-width: 801px) and (min-width: 600px) {
  .subsidy_media_list_search_bar > input {
    width: 250px;
  }
}

@media (max-width: 601px) and (min-width: 0) {
  .subsidy_media_list_search_bar > input {
    width: 100%;
    margin: 10px 0;
  }
}

.subsidy_media_list_search_bar > button {
  margin: 20px 0;
  width: 100px;
  height: 39px;
  line-height: 39px;
  border: none;
  color: white;
  font-weight: bold;
  background-color: #EC6104;
  border: .5px solid #EC6104;
}

@media (max-width: 801px) and (min-width: 601px) {
  .subsidy_media_list_search_bar > button {
    width: 80px;
  }
}

@media (max-width: 601px) and (min-width: 0px) {
  .subsidy_media_list_search_bar > button {
    width: 100%;
    margin-bottom: 30px;
  }
}

