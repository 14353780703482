.test_homepage_bottom_nav {
  width: 100vw;
  background-color: #F5F5F5;
  border: 1px solid #EBEBEB;
  margin-top: 100px;
}

.test_homepage_bottom_nav_top {
  display: flex;
  justify-content: space-between;
  width: 1000px;
  padding: 0 calc((100vw - 1000px) / 2);
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .test_homepage_bottom_nav_top {
    width: 960px;
    padding: 0px calc((100% - 960px) / 2) 0;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .test_homepage_bottom_nav_top {
    width: 740px;
    padding: 30px calc((100vw - 740px) / 2) 0;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .test_homepage_bottom_nav_top {
    width: 540px;
    padding: 30px calc((100vw - 540px) / 2) 0;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .test_homepage_bottom_nav_top {
    width: 90vw;
    padding: 30px 5vw 50px;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .test_homepage_bottom_nav_top_left {
    width: 240px;
    margin: 0px calc((100% - 240px) / 2) 30px;
  }
}

.test_homepage_bottom_nav_top_left > img {
  width: 100px;
  height: 100px;
  margin: 100px 70px 30px;
  object-fit: contain;
}

.test_homepage_bottom_nav_top_left > h4 {
  width: 240px;
  text-align: center;
}

.test_homepage_bottom_nav_top_left > p {
  width: 240px;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
}

.test_homepage_bottom_nav_top_right {
  display: flex;
  justify-content: space-between;
  margin: 100px 0 0;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .test_homepage_bottom_nav_top_right_1000 {
    display: none;
  }
}

@media (max-width: 800px) and (min-width: 0px) {
  .test_homepage_bottom_nav_top_right_800 {
    display: none;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .test_homepage_bottom_nav_top_right_600 {
    display: none;
  }
}

.test_homepage_bottom_nav_top_right > div {
  width: 150px;
}

.test_homepage_bottom_nav_top_right > div > h4 {
  font-size: 15px;
  margin-bottom: 10px;
}

.test_homepage_bottom_nav_top_right > div > a >  p {
  font-size: 13px;
  margin-bottom: 10px;
  color: #222222;
}

.test_homepage_bottom_nav_bottom {
  display: flex;
  justify-content: space-between;
  background-color: #EBEBEB;
  width: 70vw;
  padding: 20px 15vw;
  margin-top: 80px;
}

@media (max-width: 600px) and (min-width: 0px) {
  .test_homepage_bottom_nav_bottom {
    justify-content: space-evenly;
    margin: 0;
  }
}

.test_homepage_bottom_nav_bottom > div {
  display: flex;
  justify-content: space-between;
  width: 230px;
}

@media (max-width: 600px) and (min-width: 0px) {
  .test_homepage_bottom_nav_bottom > div {
    display: none;
  }
}

.test_homepage_bottom_nav_bottom > div > a > p {
  font-size: 13px;
  color: #222222;
  text-decoration: none;
}

.test_homepage_bottom_nav_bottom > p {
  font-size: 13px;
  color: #222222;
}



