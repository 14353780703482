.case_add {
  width: 100vw;
  height: 100vh;
  background-color: #F4F2EB;
}

.case_add_title {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: 0px 20% 0;
}

.case_add_title > h4 {
  width: 400px;
  font-size: 25px;
  text-align: center;
  color: #222222;
  padding: 70px 0 0;
}

.case_add_title > h5 {
  width: 400px;
  font-size: 25px;
  text-align: center;
  color: #222222;
  padding: 70px 0 0;
}

.case_add_text {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: 50px 20% 0;
}

.case_add_text > textarea {
  font-size: 13px;
  width: 400px;
  height: 500px;
  resize: none;
  border: 1px solid #222222;
}

.case_add_text > div {
  font-size: 13px;
  width: 400px;
  height: 500px;
  background-color: white;
  border: 1px solid #222222;
  word-break: break-all;
  overflow: hidden;
}

.case_add_btn {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: 50px 20% 0;
}

.case_add_btn > button {
  border: none;
  width: 400px;
  height: 40px;
  background-color: #222222;
  color: white;
}

