.management_review_footer {
  width: 900px;
  padding: 50px calc((100vw - 900px) / 2);
  background-color: #222222;
  display: flex;
}

@media (max-width: 900px) and (min-width: 700px) {
  .management_review_footer {
    width: 700px;
    padding: 50px calc((100vw - 700px) / 2);
    background-color: #222222;
    display: flex;
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .management_review_footer {
    display: block;
    width: 390px;
    padding: 50px calc((100vw - 390px) / 2);
    background-color: #222222;
    display: flex;
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .management_review_footer {
    text-align: center;
  }
}

.management_review_footer_info > a > div {
  display: flex;
}

@media (max-width: 700px) and (min-width: 0px) {
  .management_review_footer_info {
    width: 400px;
    margin:  0 auto;
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .management_review_footer_info {
    width: 200px;
  }
}

.management_review_footer_info > a > div > img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.management_review_footer_info > a > div > h4 {
  color: white;
  line-height: 40px;
  margin-left: 15px;
  font-size: 15px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.management_review_footer_info > p {
  color: white;
  font-size: 13px;
  line-height: 25px;
  margin-top: 10px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.management_review_footer_info > h5 {
  color: white;
  font-size: 13px;
  line-height: 25px;
  margin: 20px 0;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.management_review_footer_link {
  margin-left: 130px;
}

@media (max-width: 900px) and (min-width: 700px) {
  .management_review_footer_link {
    margin-left: 30px;
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .management_review_footer_link {
    display: none;
  }
}

.management_review_footer_link > h4 {
  font-size: 15px;
  color: white;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.management_review_footer_link > div {
  display: flex;
  margin: 10px 0 30px;
}

.management_review_footer_link > div > a > p {
  font-size: 15px;
  color: white;
  margin-right: 40px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 900px) and (min-width: 0px) {
  .management_review_footer_link > div > a > p:nth-child(4) {
    display: none;
  }
}

