.task {
  padding: 0 0vw;
  background-color: #F5F2EA;
  width: 100vw;
  margin: 0 auto;
  min-height: 100vh;
  padding-bottom: 100px;
}


.task_nav {
  position: fixed;
  width: 100vw;
  height: 70px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.task_nav > img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  padding: 20px;
  border-right: 1px solid #eaeaea;
}

.task_nav > div {
  display: flex;
  justify-content: space-between;
  width: 150px;
  margin-right: 30px;
}

.task_nav > div > p {
  line-height: 30px;
  margin: 20px 0;
  color: #222222;
}

.task_nav > div > img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin: 15px 0;
  border-radius: 100px;
}

.task_content_nav {
  position: fixed;
  width: 70px;
  height: calc(100vh - 70px);
  background-color: #222222;
  top: 70px;
  z-index: 1;
}

.task_content_nav_title {
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  color: #222222;
  margin: 15px;
  border-radius: 5px;
  border: 1px solid white;
}

.task_content_nav_title_icon {
  width: 20px;
  height: 20px;
  color: white;
  margin: 10px;
}

.task_content_nav_details {
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  color: white;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  -webkit-text-stroke: .3px #222222;
  margin: 15px 15px 0 15px;
  font-weight: bold;
}


.task_content_box_title {
  width: 1200px;
  padding: 130px calc(7.5vw - 35px) 0 calc(7.5vw + 35px);
}

.task_content_box_title_icon {
  width: 15px;
  height: 15px;
  padding: 2.5px;
  color: #222222;
}

.task_content_box_title_title {
  display: flex;
  font-size: 13px;
  color: #222222;
  line-height: 20px;
}

.task_content_box_title_sub {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 50px;
}

.task_content_box_title_sub > div {
  width: 70px;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
  background-color: #222222;
  color: white;
  text-align: center;
  border-radius: 3px;
}

.task_content_box_title_sub > h2 {
  text-align: left;
  color: #222222;
  font-size: 20px;
}


.task_table {
  position: relative;
  width: 1200px;
  margin: 0 calc(7.5vw - 35px) 50px calc(7.5vw + 35px);
  background-color: white;
  border-radius: 5px;
  padding-bottom: 30px;
}

.task_table_title {
  height: 80px;
  display: flex;
  justify-content: space-between;
  width: 1120px;
  padding: 0px 40px;
  border-bottom: .5px solid #ededed;
}

.task_table_title > p {
  line-height: 80px;
  font-size: 15px;
  color: #222222;
}

.task_table_title > div {
  line-height: 30px;
  background-color: #222222;
  width: 100px;
  height: 30px;
  margin: 25px 0;
  border-radius: 3px;
  color: white;
  font-size: 13px;
  text-align: center;
}


.task_table_topic {
  display: flex;
  width: 1120px;
  padding:  5px 40px;
  border-bottom: .5px solid #ededed;
}

.task_table_topic > p {
  line-height: 50px;
  font-size: 15px;
  color: #222222;
  width: 240px;
  text-align: left;
}

.task_table_topic > div {
  display: flex;
  width: 850px;
  margin: 0 0 0 30px;
}

.task_table_topic > div > p {
  line-height: 50px;
  color: #222222;
  font-size: 15px;
  text-align: center;
  margin-left: 3px;
}


.task_table_content {
  display: flex;
  width: 1120px;
  padding: 5px 40px;
  justify-content: space-between;
  border-bottom: .5px solid #ededed;
  text-align: center;
}

.task_table_content_left {
  display: flex;
  color: #555555;
  width: 240px;
}

.task_table_content_left > div {
  text-align: left;
  width: 35px;
  height: 35px;
  border-radius: 3px;
  object-fit: contain;
  margin: 6.5px 10px 6.5px 0;
  font-size: 15px;
  text-align: center;
  line-height: 35px;
  font-weight: bold;
  color: #FF7847;
  border: 1px solid #FF7847;
}

.task_table_content_left > p {
  text-align: left;
  width: 140px;
  line-height: 50px;
  font-size: 15px;
  color: #555555;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.task_table_content_left > img {
  text-align: left;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  object-fit: contain;
  margin: 10px;
  font-size: 15px;
  color: #555555;
}

.task_table_content_left_icon {
  width: 15px;
  height: 15px;
  margin: 17.5px 0 17.5px 10px;
}

.task_table_content_right {
  display: flex;
  width: 850px;
  margin-left: 30px;
}

.task_table_content_right > div {
  position: relative;
  line-height: 40px;
  height: 40px;
  color: #555555;
  font-size: 15px;
  margin: 5px 0 5px 3px;
  color: white;
}


.task_content_box {
  width: calc(100vw - 70px);
}


.task_create {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}

.task_create_background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: .2;
}

.task_content {
  position: absolute;
  left: 0;
  top: 0;
  width: 400px;
  padding: 30px 50px;
  height: 500px;
  background-color: white;
  margin: calc(50vh - 265px) calc(50vw - 250px);
}
請求書の番号を書いて送って無視した後立米大すごいすごい子セックスを20時ですするわからん。なんかもう音声入力やばいやばいやばいやばいをセットすると言うちゃん。音声入力音声入力とほぼほぼ一緒でない子すごい頭頭ってきてるわ。まじで知らない
.task_content_title {
  height: 70px;
  font-size: 18px;
  width: 400px;
  margin: 0px 0 0px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  color: white;
  font-weight: bold;
  border: none;
  line-height: 40px;
  border-radius: 3px;
}

.task_content_title > input {
  width: 320px;
  border: none;
  border: .5px solid  #373737;
  line-height: 40px;
  height: 20px;
  height: 39px;
  font-size: 15px;
  color: #373737;
  background-color: #FAFAFA;
  padding: 0 10px;
}

.task_content_title > img {
  width: 39.5px;
  border: none;
  border: .5px solid  #373737;
  height: 39.5px;
  font-size: 15px;
  color: #373737;
  background-color: #FAFAFA;
  object-fit: contain;
}

.task_content > textarea {
  padding: 10px;
  width: 380px;
  border: none;
  border: .5px solid  #373737;
  line-height: 30px;
  height: 350px;
  font-size: 15px;
  color: #373737;
  background-color: #FAFAFA;
  margin: 0px 0px 0;
  resize: none;
}


.task_content_btn {
  display: flex;
  width: 400px;
  justify-content: space-between;
  margin: 20px 0px 0;
}

.task_content_btn > button {
  width: 180px;
  border: none;
  line-height: 40px;
  height: 40px;
  font-size: 15px;
  color: white;
  text-align: center;
  background-color: #222222;
}


.task_checkbox {
  position: absolute;
  top: 0;
  z-index: 3;
  background-color: rgb(189, 189, 189);
  position: absolute;
	display: inline-block;
	line-height: 32px;
	text-align: center;
	background-color: white;
  z-index: 10;
  left: 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.task_checkbox:before {
	content: "";
	position: absolute;
	top: -8px; left: 50%;
	margin-left: -9px;
	display: block;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 0 9px 9px 9px;
	border-color: transparent transparent white transparent;
	z-index: 10;
}

.task_checkbox > div {
  height: 40px;
  background-color: #5AC47D;
  margin: 10px auto 10px;
  font-size: 15px;
  line-height: 40px;
  color: white;
}

