.help_service_title {
  width: 80%;
  padding: 0px 10% 50px;
  display: flex;
  background-color: #fafafa;
  justify-content: space-between;
}

@media (max-width: 1300px) and (min-width: 500px) {
  .help_service_title {
    display: block;
    padding: 0 10%;
  }
}

.help_service_title_left {
  display: flex;
  padding-top: 110px;
}

@media (max-width: 900px) and (min-width: 0px) {
  .help_service_title_left {
    padding-top: 0px;
  }
}

.help_service_title_left > h6 {
  font-size: 25px;
  width: 50px;
  height: 50px;
  text-align: center;
  color: white;
  background-color: #222222;
  border-radius: 100px;
  line-height: 45px;
}

@media (max-width: 900px) and (min-width: 500px) {
  .help_service_title_left > h6 {
    font-size: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_service_title_left > h6 {
    font-size: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.help_service_title_left > h3 {
  font-size: 30px;
  line-height: 50px;
  padding-left: 20px;
}

@media (max-width: 900px) and (min-width: 500px) {
  .help_service_title_left > h3 {
    font-size: 26px;
    line-height: 40px;
    padding-left: 20px;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_service_title_left > h3 {
    font-size: 20px;
    line-height: 40px;
    padding-left: 20px;
  }
}

.help_service_title_right {
  display: flex;
  margin-top: 15px;
}

@media (max-width: 900px) and (min-width: 500px) {
  .help_service_title_right {
    height: 110px;
  }
}

.help_service_title_right > img {
  width: 130px;
  height: 130px;
  object-fit: contain;
  margin-top: 50px;
}

@media (max-width: 900px) and (min-width: 0px) {
  .help_service_title_right {
    display: none;
  }
}

@media (min-width: 900px) {
  .help_service_title_text {
    display: none;
  }
}

@media (max-width: 900px) and (min-width: 500px) {
  .help_service_title_text {
    width: 80vw;
    padding: 20px 10vw 0;
    color: #373737;
    text-align: left;
    background-color: #fafafa;
    font-size: 15px;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_service_title_text {
    width: 80vw;
    padding: 0px 10vw 0;
    color: #373737;
    text-align: left;
    background-color: #fafafa;
    font-size: 15px;
  }
}

.help_service_title_right > div {
  position: relative;
  width: 500px;
}

.help_service_title_right > div > img {
  position: absolute;
  width: 500px;
  height: 150px;
  left: 0;
  object-fit: contain;
}

@media (max-width: 700px) and (min-width: 500px) {
  .help_service_title_right > div > img {
    width: 400px;
    height: 150px;
  }
}

.help_service_title_right > div > p {
  position: absolute;
  width: 370px;
  left: 0;
  z-index: 1;
  margin: 35px 20px 20px 90px;
  color: #373737;
  text-align: left;
}

@media (max-width: 700px) and (min-width: 500px) {
  .help_service_title_right > div > p {
    width: 310px;
    margin: 38px 20px 20px 65px;
    font-size: 15px;
  }
}

.help_service1_img {
  width: 80%;
  padding: 0px 10% 50px;
  background-color: #fafafa;
}



.help_service1_img > img {
  width: 100%;
  padding: 30px 0;
  object-fit: contain;
}


