.bid_QA_box {
  width: 1000px;
  border: 1px solid #39302C;
  border-radius: 5px;
  margin: 80px auto 50px;
  background-color: white;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .bid_QA_box {
    width: 800px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .bid_QA_box {
    width: 600px;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .bid_QA_box {
    width: 90%;
  }
}

.bid_QA_box > div {
  border-bottom: 1px solid #39302C;
}

.bid_QA_q {
  display: flex;
  background-color: #D70A0A;
  color: white;
}

.bid_QA_q >  h3 {
  font-size: 20px;
  padding: 20px;
  line-height: 30px;
}

.bid_QA_q >  h4 {
  font-size: 15px;
  padding: 20px;
  line-height: 30px;
}

.bid_QA_a {
  display: flex;
  color: #222222;
  padding: 10px 0;
}

.bid_QA_a >  h3 {
  font-size: 20px;
  padding: 20px;
}

.bid_QA_a >  h4 {
  font-size: 15px;
  padding: 20px;
  font-weight: 300;
  color: #222222;
}
