.simulator_result_result_title {
  display: flex;
  padding-top: 70px;
  width: 100vw;
  margin: 0 auto;
}

.simulator_result_result_title > img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 3px solid #EC6104;
  margin-right: 20px;
}

.simulator_result_result_title > h4 {
  width: 100%;
  font-weight: bold;
  color: #222222;
  font-size: 25px;
  line-height: 46px;
  text-align: center;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.simulator_result_result_desc {
  width: 800px;
  box-shadow: 0px 0px 2px rgb(197, 197, 197);
  margin: 50px auto 100px;
  padding: 20px 0;
}

@media (max-width: 700px) and (min-width: 0px) {
  .simulator_result_result_desc {
    width: 90vw;
  }
}

.simulator_result_result_desc > div {
  margin: 30px 5%;
  width: 90%;
  display: flex;
  justify-content: space-between;
  border-bottom: .5px solid #c5c5c5;
  padding-bottom: 30px;
}

.simulator_result_result_desc > div > div {
  display: flex;
}

.simulator_result_result_desc > div > div > h5 {
  font-size: 18px;
  color: #222222;
  line-height: 40px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 499px) and (min-width: 0px) {
  .simulator_result_result_desc > div > div > h5 {
    width: 200px;
  }
}

.simulator_question {
  width: 20px;
  height: 20px;
  background-color: white;
  color: #222222;
  margin: 10px 0 10px 15px;
}

@media (max-width: 4990px) and (min-width: 0px) {
  .simulator_question {
    display: none;
  }  
}

.simulator_result_result_desc > div > h4 {
  width: 130px;
  height: 40px;
  font-size: 15px;
  border-radius: 100px;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  background-color: #EC6104;
  border: 1px solid #EC6104;
  color: white;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 499px) and (min-width: 0px) {
  .simulator_result_result_desc > div > h4 {
    width: 100px;
  }
}

.simulator_result_result_desc > div > p {
  font-size: 15px;
  border-radius: 100px;
  text-align: left;
  line-height: 25px;
  color: #222222;
  padding-top: 10px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}






