/* content */

.management_review_content_background {
  width: 100vw;
  height: auto;
  background-color: #F4EFEB;
  padding: 100px 0 50px;
}

.management_review_content_background > h4 {
  text-align: center;
  color: #222222;
  font-size: 30px;
  margin: 30px 0 35px;
}

.management_review_content_background > img {
  width: 300px;
  margin: 0px calc((100vw - 300px) / 2) 50px;
}

.management_review_content_1 {
  width: 800px;
  height: auto;
  background-color: white;
  border: 1px solid #222222;
  border-radius: 10px;
  margin: 30px auto;
}

.management_review_content_1 > h4 {
  width: 550px;
  text-align: center;
  margin: 50px auto 50px;
  color: #222222;
  font-size: 20px;
}

.management_review_content_1 > div {
  width: 600px;
  /* display: flex; */
  /* justify-content: space-between; */
  border-bottom: .5px solid #222222;
  margin: 0 auto;
  padding-bottom: 10px;
  margin-bottom: 50px;
}

.management_review_content_1 > h5 {
  width: 600px;
  text-align: left;
  color: #222222;
  font-size: 18px;
  line-height: 40px;
  margin: 10px auto 20px;
}

.management_review_content_1 > select {
  width: 600px;
  height: 40px;
  border: none;
  background-color: #fafafa;
  border-bottom: 0.5px solid #222222;
  background-color: white;
  margin: 0 calc((800px - 600px) / 2) 30px;
}

.management_review_content_1 > input {
  width: 600px;
  height: 40px;
  border: none;
  text-indent: 1em;
  background-color: #fafafa;
  border-bottom: 0.5px solid #222222;
  background-color: white;
  margin: 0 calc((800px - 600px) / 2) 30px;
}

.management_review_content_1 > div > h5 {
  width: 100%;
  text-align: left;
  color: #222222;
  font-size: 18px;
  line-height: 40px;
}

.management_review_content_1 > div > p {
  width: 100%;
  text-align: left;
  color: #222222;
  font-size: 13px;
  line-height: 40px;
  font-weight: 400;
}

.management_review_content_1 > div > select {
  width: 100%;
  height: 40px;
  border: none;
  background-color: #fafafa;
}

.management_review_content_1 > div > input {
  width: 100%;
  height: 50px;
  border: none;
  text-indent: 1em;
  background-color: #fafafa;
  margin-top: 20px;
}


/* bid_list_number */

.management_review_list_number {
  width: 220px;
  display: flex;
  justify-content: space-between;
  margin: 70px auto 0;
}

.management_review_list_number > div {
  width: 100px;
  height: 40px;
  line-height: 20px;
  text-align: center;
  line-height: 40px;
  background-color: white;
  border: 1px solid #D70A0A;
  font-weight: bold;
  color: #D70A0A;
  border-radius: 2px;
}

