.AdBusiness_what_box2 {
  width: 450px;
  height: 450px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 0px 5px rgb(205, 205, 205);
  margin: 50px 0 0;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .AdBusiness_what_box2 {
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_what_box2 {
    width: 350px;
    height: 350px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .AdBusiness_what_box2 {
    width: 500px;
    height: 500px;
    margin: 50px auto;
  }
}

@media (max-width: 600px) and (min-width: 450px) {
  .AdBusiness_what_box2 {
    width: 450px;
    height: 450px;
    margin: 50px auto;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_what_box2 {
    width: 350px;
    height: 350px;
    margin: 50px auto;
  }
}

.AdBusiness_what_box2 > div {
  padding: 50px;
  width: 350px;
  height: 350px;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .AdBusiness_what_box2 > div {
    padding: 50px;
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_what_box2 > div {
    padding: 30px;
    width: 280px;
    height: 280px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .AdBusiness_what_box2 > div {
    padding: 50px;
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_what_box2 > div {
    padding: 25px;
    width: 300px;
    height: 300px;
  }
}

.AdBusiness_what_box2 > div > h4 {
  font-size: 18px;
  color: #222222;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .AdBusiness_what_box2 > div > h4 {
    font-size: 15px;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_what_box2 > div > h4 {
    font-size: 13px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .AdBusiness_what_box2 > div > h4 {
    font-size: 18px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_what_box2 > div > h4 {
    font-size: 18px;
  }
}

.AdBusiness_what_box2 > div > h3 {
  font-size: 30px;
  color: #222222;
  padding: 10px 0 0 0;
  line-height: 50px;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .AdBusiness_what_box2 > div > h3 {
    font-size: 27px;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_what_box2 > div > h3 {
    font-size: 23px;
    line-height: 40px;
    margin-bottom: 10px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .AdBusiness_what_box2 > div > h3 {
    font-size: 30px;
    line-height: 55px;
    margin-bottom: 10px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_what_box2 > div > h3 {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 10px;
  }
}

.AdBusiness_what_box2 > div > p {
  font-size: 15px;
  color: #222222;
  line-height: 30px;
  padding: 30px 0 0 0;
}

.AdBusiness_what_box2 > div > img {
  width: 350px;
  margin-top: 20px;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .AdBusiness_what_box2 > div > img {
    width: 300px;
    margin-top: 0px;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_what_box2 > div > img {
    width: 300px;
    margin-top: 0px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .AdBusiness_what_box2 > div > img {
    width: 400px;
    margin-top: 30px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_what_box2 > div > img {
    width: 300px;
    margin-top: 0px;
  }
}



