.test_homeimage_border {
  width: 1000px;
  padding: 0 calc((100% - 1000px) / 2) 0px;
  background-color: #ECE8E4;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .test_homeimage_border {
    width: 960px;
    padding: 0px calc((100% - 960px) / 2) 0;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .test_homeimage_border {
    width: 740px;
    padding: 30px calc((100vw - 740px) / 2) 0;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .test_homeimage_border {
    width: 540px;
    padding: 30px calc((100vw - 540px) / 2) 0;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .test_homeimage_border {
    width: 90vw;
    padding: 30px 5vw 50px;
  }
}

.test_homeimage_border > h4 {
  font-size: 28px;
  text-align: center;
  color: #222222;
  padding-top: 60px;
}

.test_homeimage_border > div {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 800px) and (min-width: 600px) {
  .test_homeimage_border_third {
    display: none;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .test_homeimage_border > div {
    display: block;
    width: 250px;
    margin: 0 calc((100% - 250px) / 2);
  }
}

.test_homeimage_border > div > a > div {
  width: 280px;
  height: 400px;
  background-color: white;
  margin: 50px 0 80px;
  border-radius: 10px;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .test_homeimage_border > div > a > div {
    width: 230px;
    height: 400px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .test_homeimage_border > div > a > div {
    width: 250px;
    height: 400px;
  }
}

.test_homeimage_border > div > a > div > img {
  width: 240px;
  height: 150px;
  object-fit: cover;
  padding: 25px 0 0;
  margin: 20px 20px 18px;
  border-radius: 7px;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .test_homeimage_border > div > a > div > img {
    width: 190px;
    height: 150px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .test_homeimage_border > div > a > div > img {
    width: 210px;
    height: 150px;
  }
}

.test_homeimage_border > div > a > div > h4 {
  font-size: 15px;
  color: #222222;
  padding: 0 20px;
  text-align: left;
}

.test_homeimage_border > div > a > div > p {
  font-size: 13px;
  color: #222222;
  padding-top: 60px;
  padding: 10px 20px 0;
  text-align: left;
  line-height: 30px;
}