.masato_nav {
  width: 80vw;
  padding: 30px 10vw 100px;
  background-color: #FAFAFA;
}

.masato_nav_text {
  display: flex;
  justify-content: space-between;
}

.masato_nav_text > h2 {
  font-size: 25px;
  line-height: 60px;
  font-weight: 800;
}

.masato_nav_text > a > h3 {
  font-size: 15px;
  line-height: 60px;
  font-weight: 800;
  text-decoration: none;
  color: #222222;
}

@media (max-width: 700px) and (min-width: 0px) {
  .masato_nav_text > h2 {
    font-size: 15px;
    line-height: 60px;
    font-weight: 800;
  }
}

/* @media (max-width: 450px) and (min-width: 0px) {
  .masato_nav_text > h3 {
    display: none;
  }
} */

.masato_nav_line {
  width: 100%;
  height: 2px;
  background-color: #222222;
  margin-bottom: 70px;
}

.grid {
  width: 80vw;
}

.grid-item > img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  margin-bottom: 50px;
}

@media (max-width: 10000px) and (min-width: 700px) {
  .grid {
    column-count: 3;
    column-gap: 8%;
  }
}

@media (max-width: 700px) and (min-width: 0px) {
  .grid {
    width: 100%;
    column-count: 2;
    column-gap: 8%;
  }

  .masato_nav {
    width: 90vw;
    padding: 30px 5vw 100px;
    background-color: #FAFAFA;
  }

  img {
    margin-bottom: 20px;
  }
}

.grid-item {
  position: relative;
  height: 100%;
  width: 100%;
  margin-bottom: 1rem;
}
