/* media_company */

.media_company {
  width: 750px;
  padding: 170px calc((100vw - 750px) / 2) 100px;
  background-color: #fdf4ec;
  margin: 0 auto;
}

.media_company > * > p,
.media_company > * > p > strong,
.media_company > * > h1,
.media_company > * > h2,
.media_company > * > h3,
.media_company > * > h4,
.media_company > * > h5,
.media_company > * > h6,
.media_company > * > .media_company_contents_title,
.media_company > * > a {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 1300px) and (min-width: 1000px) {
  .media_company {
    width: 90%;
    padding: 170px 5% 100px;
  }
}

@media (max-width: 1000px) and (min-width: 450px) {
  .media_company {
    width: 90%;
    padding: 170px 5% 100px;
    display: block;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .media_company {
    width: 100%;
    padding: 100px 0 100px;
    display: block;
  }
}


/* 記事トップ */

.media_company_article {
  width: 750px;
  padding-bottom: 150px;
  background-color: white;
}

@media (max-width: 1350px) and (min-width: 1299px) {
  .media_company_article {
    width: 650px;
  }
}

@media (max-width: 1200px) and (min-width: 1150px) {
  .media_company_article {
    width: 650px;
  }
}

@media (max-width: 1150px) and (min-width: 1100px) {
  .media_company_article {
    width: 600px;
  }
}

@media (max-width: 1100px) and (min-width: 1000px) {
  .media_company_article {
    width: 550px;
  }
}

@media (max-width: 1000px) and (min-width: 0px) {
  .media_company_article {
    width: 100%;
  }
}

.media_company_date {
  padding: 50px 5% 0;
  width: 90%;
  color: #666666;
  font-size: 13px;
  text-align: left;
  font-weight: bold;
}

.media_company_title {
  text-align: left;
  padding: 20px 0 15px;
  width: 90%;
  color: #444444;
  font-size: 20px;
  border-bottom: 3px solid #EB6231;
  margin: 0px 5% 50px;
}

.media_company_img {
  width: 100%;
}

.media_company_title_img {
  width: 90%;
  margin: 0 5%;
}

/* タイトル */

.media_company_text_title > h2 {
  margin: 80px 5% 0;
  width: 84%;
  padding: 0 3%;
  text-align: left;
  height: 60px;
  line-height: 60px;
  background-color: #EB6231;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

@media (max-width: 450px) and (min-width: 0px) {
  .media_company_text_title > h2 {
    margin: 80px 5% 0;
    width: 84%;
    padding: 0 3%;
  }
}

/* 文章 */

.media_company_text_text {
  margin: 0px 5% 0;
  width: 90%;
  font-size: 15px;
  line-height: 38px;
  color: #222222;
  text-align: left;
  word-break: break-all;
}

.media_company_text_text > span {
  color: #444444;
  font-size: 15px;
  text-align: left;
  line-height: 23px;
  font-weight: bold;
  padding: 0 3px;
  background: linear-gradient(transparent 60%, #ffdfbb 60%);
}


@media (max-width: 450px) and (min-width: 0px) {
  .media_company_text_text {
    margin: 0px 5% 0;
    width: 90%;
  }
}

.media_company_text_text_box {
  margin: 30px 5% 0;
  width: 80%;
  border: .5px solid #666666;
  font-size: 15px;
  line-height: 32px;
  color: #464646;
  text-align: left;
  background-color: #fafafa;
  padding: 15px 5% 15px;
}

@media (max-width: 450px) and (min-width: 0px) {
  .media_company_text_text_box {
    margin: 30px 5% 0;
    width: 80%;
  }
}

.media_company_text_text_box_title {
  color: #444444;
  font-size: 15px;
  text-align: left;
  line-height: 23px;
  font-weight: bold;
  padding: 0 3px;
  background: linear-gradient(transparent 60%, #abc0d6 60%);
}

.media_company_text_text_box_site {
  color: #1086c1;
  font-size: 15px;
  line-height: 23px;
  font-weight: bold;
  float: right;
}

