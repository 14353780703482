@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
}


.top_hostess_header {
  width: 80vw;
  height: 40px;
  background-color: white;
  border-bottom: 1px solid #DF5ABA;
  display: flex;
  justify-content: space-between;
  padding: 15px 10vw;
}

.top_hostess_header > div {
  display: flex;
}

.top_hostess_header > div > img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.top_hostess_header > div > h1 {
  line-height: 40px;
  font-size: 14px;
  color: #222222;
  padding-left: 20px;

}

.top_hostess_header > button {
  border: none;
  width: 130px;
  height: 40px;
  border-radius: 3px;
  color: white;
  background-color: #DF5ABA;
  font-weight: bold;
}


.top_hostess_contents {
  width: 90vw;
  padding: 100px 5vw;
  background-color: #FDF7FE;
  display: flex;
  justify-content: space-between;
}

.top_hostess_contents_info {
  width: 27%;
  background-color: white;
  box-shadow: 0px 0px 1px #cccccc;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
}

.top_graybox {
  width: 85%;
  margin: 24px auto;
  padding: 16px;
  background-color: 	#F0F0F0;
  font-weight: bold;
}


.top_sarea {
  margin-top: 8px;
  padding: 6px;
  width: 100%;
}

.top_tennpomei > p {
  margin-top: 8px;
}

.top_stennpomei {
  margin-top: 8px;
  width: 100%;
  padding: 6px;
}

.top_jannru > p {
  margin-top: 8px;
}

.top_sjannru {
  margin-top: 8px;
  width: 100%;
  padding: 6px;
}


/* 'https://i.top_pinimg.top_com/736x/1d/74/19/1d7419d3ac4e66aba03bfa9add637ed0.top_jpg' */



.top_hostess_contents_ads {
  width: 73%;
  height: 1000px;
}

.top_uenomoji {
  display: flex;
  margin: 8px 5%;
  font-weight: bold;
}

.top_sotowaku {
  width: 95%;
  height: auto;
  margin: 0 auto 36px;
  padding-top: 4px;
  background-color: white;
  font-weight: bold;
  position: relative;
  box-shadow: 0px 0px 1px #cccccc;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
}

.top_title {
  margin: 16px 5%;
}

.top_title > p {
  font-size: 12px;
}

.top_feature {
  display: flex;
  margin: 24px 0 0 36px;
}

.top_oneesann {
  margin-left: 36px;
}

.top_oneesann > img {
  width: 22%;
  margin: 0 1%;
}

.top_syousai {
  position: absolute;
  top: 24px;
  right: 36px;
  width: 120px;
}

.top_syousai > button {
  width: 120px;
  height: 40px;
  background-color: #DF5ABA;
  border: 1px solid #DF5ABA;
  border-radius: 4px;
  color: white;

}


