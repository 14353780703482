.photo_nav {
  position: fixed;
  width: 80vw;
  height: 60px;
  padding: 0 10vw;
  z-index: 10;
  border-bottom: .5px solid rgb(212, 212, 212);
  display: flex;
  justify-content: space-between;
  background-color: white;
  color: #222222;
}

.photo_nav_icon {
  margin: 10px 0;
  display: flex;
}

.photo_nav_icon > img {
  height: 25px;
  margin: 7.5px 0;
}

.photo_nav_icon > h2 {
  line-height: 40px;
  margin-left: 20px;
  color: #333333;
  font-size: 20px;
  font-weight: 300;
}

.photo_nav_items {
  margin: 10px 0;
  display: flex;
  width: 300px;
  justify-content: space-between;
}

.photo_nav_items_company {
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  color: #333333;
  font-weight: bold;
  font-weight: 400;
}

.photo_nav_items_contact {
  text-align: center;
  border: none;
  width: 150px;
  height: 40px;
  font-size: 15px;
  line-height: 50px;
  background-color: #FF8D89;
  color: white;
  font-weight: bold;
  line-height: 40px;
  border-radius: 5px;
}

@media (max-width: 1300px) and (min-width: 700px) {
  .photo_nav {
    width: 90vw;
    padding: 0 5vw;
  }
}

@media (max-width: 700px) and (min-width: 450px) {
  .photo_nav {
    width: 90vw;
    padding: 0 5vw;
  }

  .photo_nav_icon > h2 {
    display: none;
  }

  .photo_nav_items_company {
    display: none;
  }

  .photo_nav_items {
    width: 150px;
  }
}

@media (max-width: 450px)  {
  .photo_nav {
    width: 90vw;
    padding: 0 5vw;
  }

  .photo_nav_icon > h2 {
    display: none;
  }

  .photo_nav_items_company {
    display: none;
  }

  .photo_nav_items {
    width: 150px;
  }
}
