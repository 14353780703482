.company_header {
  width: 100vw;
  padding: 200px 0;
  background-color: #D9313C;
  text-align: center;
}

.company_header > h1 {
  font-size: 70px;
  color: white;
}

.company_header > h2 {
  font-size: 20px;
  color: white;
}

.company_content {
  width: 900px;
  padding: 100px 0 130px;
  border: 1px solid #222222;
  margin: -100px auto 150px;
  background-color: white;
}

@media (max-width: 950px) and (min-width: 501px) {
  .company_content {
    width: 500px;
  }
}

@media (max-width: 500px) and (min-width: 0) {
  .company_content {
    width: 90vw;
    border: none;
    margin: 0px auto;
    padding: 50px 0 150px;
  }
}

.company_content > div {
  width: 550px;
  display: flex;
  height: 60px;
  margin: 0 auto;
  justify-content: space-between;
  border-bottom: .5px solid #222222;
  margin-top: 40px;
}

@media (max-width: 950px) and (min-width: 500px) {
  .company_content > div {
    width: 350px;
  }
}

@media (max-width: 500px) and (min-width: 0) {
  .company_content > div {
    width: 90vw;
  }
}

.company_content > div > h3 {
  font-size: 15px;
  color: #222222;
  line-height: 60px;
}

.company_content > div > h4 {
  font-size: 15px;
  color: #222222;
  line-height: 30px;
  font-weight: 400;
  width: 300px;
  text-align: left;
  margin-top: 30px;
}

@media (max-width: 950px) and (min-width: 500px) {
  .company_content > div > h4 {
    width: 230px;
  }
}

@media (max-width: 500px) and (min-width: 0) {
  .company_content > div > h4 {
    width: 230px;
  }
}



