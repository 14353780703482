.AdBusiness_Strong {
  width: 1000px;
  padding: 120px calc((100vw - 1000px) / 2) 100px;
  background-color: #F2F2F2;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .AdBusiness_Strong {
    width: 900px;
    padding: 120px calc((100vw - 900px) / 2) 100px;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_Strong {
    width: 750px;
    padding: 120px calc((100vw - 750px) / 2) 100px;
  }
}

@media (max-width: 800px) and (min-width: 450px) {
  .AdBusiness_Strong {
    width: 430px;
    padding: 120px calc((100vw - 430px) / 2) 100px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_Strong {
    width: 350px;
    padding: 120px calc((100vw - 350px) / 2) 100px;
  }
}

.AdBusiness_Strong > h3 {
  font-size: 33px;
  color: #222222;
  text-align: center;
  margin-bottom: 10px;
}

@media (max-width: 800px) and (min-width: 0px) {
  .AdBusiness_Strong > h3 {
    margin-bottom: 50px;
  }
}

.AdBusiness_Strong_box {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .AdBusiness_Strong_box {
    width: 900px;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_Strong_box {
    width: 750px;
  }
}

@media (max-width: 800px) and (min-width: 450px) {
  .AdBusiness_Strong_box {
    width: 430px;
    display: block;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_Strong_box {
    width: 350px;
    display: block;
  }
}

@media (max-width: 800px) {
  .AdBusiness_Strong_text_media_big {
    width: 430px;
    display: none;
  }
}

@media (min-width: 800px) {
  .AdBusiness_Strong_text_media_small {
    width: 430px;
    display: none;
  }
}

.AdBusiness_Strong_text {
  width: 430px;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_Strong_text {
    width: 350px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_Strong_text {
    width: 350px;
  }
}

.AdBusiness_Strong_text > div {
  display: flex;
}

.AdBusiness_Strong_text > div > h6 {
  font-size: 45px;
  color: #F8D166;
  -webkit-text-stroke: .3px #222222;
  line-height: 45px;
  margin-top: 20px;
}

.AdBusiness_Strong_text > div > h5 {
  font-size: 25px;
  padding-left: 15px;
  line-height: 45px;
  color: #222222;
  margin-top: 20px;
}

.AdBusiness_Strong_text > p {
  color: #222222;
  font-size: 13px;
  line-height: 30px;
  margin-top: 30px;
}

@media (max-width: 800px) and (min-width: 450px) {
  .AdBusiness_Strong_text > p {
    margin-bottom: 30px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_Strong_text > p {
    font-size: 10px;
    line-height: 20px;
    margin-bottom: 30px;
  }
}

.AdBusiness_Strong_img {
  width: 430px;
  height: 280px;
  background-color: white;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_Strong_img {
    width: 350px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_Strong_img {
    width: 350px;
  }
}

.AdBusiness_Strong_img > img {
  width: 430px;
  height: 280px;
  object-fit: contain;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_Strong_img > img {
    width: 350px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_Strong_img > img {
    width: 350px;
  }
}

.AdBusiness_Strong_contact {
  width: 700px;
  border: 1px solid #222222;
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  padding: 50px;
}

.AdBusiness_Strong_contact > h4 {
  font-size: 20px;
  color: #222222;
  text-align: center;
}

.AdBusiness_Strong_contact > a > div {
  text-decoration: none;
  color: #222222;
  width: 200px;
  height: 40px;
  line-height: 40px;
  border: 1px solid #222222;
  text-align: center;
  border-radius: 5px;
  margin: 30px auto 0;
}

.AdBusiness_Strong_contact > a > div:hover {
  border: 1px solid #222222;
  color: white;
  background-color: #222222;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_Strong_contact {
    width: 600px;
  }
}

@media (max-width: 800px) and (min-width: 450px) {
  .AdBusiness_Strong_contact {
    width: 320px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .AdBusiness_Strong_contact {
    width: 290px;
    padding: 30px;
  }
}



