/* header */

.management_review_header {
  width: 100vw;
  height: 600px;
  background-image: url(./../../../../../../../image/bid/management/header.jpg);
  background-size: cover;
}

.management_review_header > h3 {
  font-size: 20px;
  color: white;
  padding: 250px 0 0;
  text-align: center;
}

.management_review_header > h4 {
  font-size: 40px;
  color: white;
  padding: 20px 0 0;
  text-align: center;
}

.management_review_header > p {
  width: 600px;
  text-align: center;
  font-size: 15px;
  color: white;
  padding: 20px 0 0;
  text-align: center;
  margin: 0 auto;
}