.hojokin_results {
  width: 1150px;
  padding: 100px calc((100vw - 1150px) / 2) 150px;
  text-align: center;
  z-index: 1;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .hojokin_results {
    width: 900px;
    padding: 120px calc((100vw - 900px) / 2) 100px;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .hojokin_results {
    width: 750px;
    padding: 120px calc((100vw - 750px) / 2) 100px;
  }
}

@media (max-width: 800px) and (min-width: 450px) {
  .hojokin_results {
    width: 430px;
    padding: 120px calc((100vw - 430px) / 2) 100px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .hojokin_results {
    width: 350px;
    padding: 120px calc((100vw - 350px) / 2) 100px;
  }
}

.hojokin_results > h5 {
  font-size: 20px;
  color: #373737;
}

.hojokin_results > h4 {
  font-size: 25px;
  color: #373737;
  margin: 10px 0 50px;
}


/* .hojokin_results_slider_bar {
  display: flex;
  margin: 70px 0 30px;
}

.hojokin_results_slider_bar > h4 {
  font-size: 13px;
  font-weight: 400;
  color: #373737;
  margin-right: 15px;
  margin-left: 20px;
}

.hojokin_results_slider {
  width: 1100px;
  margin: 0 0 30px;
  height: 380px;
}

.hojokin_results_slider_box {
  position: relative;
  width: 350px;
  height: 370px;
}

.hojokin_results_slider_box > div {
  position: absolute;
  width: 350px;
  height: 370px;
}

.hojokin_results_slider_box > div > img {
  position: absolute;
  width: 350px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  z-index: 0;
}

.hojokin_results_slider_box > div > div {
  position: absolute;
  width: 310px;
  height: 190px;
  background-color: white;
  margin: 150px 20px 0;
  z-index: 1;
  border: 1px solid #EAEAEA;
  border-radius: 5px;
}

.hojokin_results_slider_box > div > div > h4 {
  font-size: 15px;
  padding: 15px 15px 10px;
  text-align: left;
  color: #222222;
}

.hojokin_results_slider_box > div > div > div {
  width: 280px;
  height: .5px;
  margin: 0 auto;
  background-color: #c5c5c5;
}

.hojokin_results_slider_box > div > div > p {
  width: 270px;
  padding: 10px 15px 0;
  text-align: left;
  font-size: 12px;
  line-height: 25px;
  color: #373737;
}

.slick-prev:before,
.slick-next:before {
  color: #222222;
} */

/* ======================== */

.hojokin_results_items {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.hojokin_results_item {
  position: relative;
  width: 350px;
  height: 370px;
  margin-bottom: 50px;
}

.hojokin_results_item > div {
  position: absolute;
  width: 350px;
  height: 370px;
}

.hojokin_results_item > div > img {
  position: absolute;
  width: 350px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  z-index: 0;
  left: 0;
}

.hojokin_results_item > div > div {
  position: absolute;
  width: 310px;
  height: 215px;
  background-color: white;
  margin: 150px 20px 0;
  z-index: 1;
  border: 1px solid #EAEAEA;
  border-radius: 5px;
}

.hojokin_results_item > div > div > h4 {
  font-size: 15px;
  padding: 15px 15px 10px;
  text-align: left;
  color: #222222;
}

.hojokin_results_item > div > div > div {
  width: 280px;
  height: .5px;
  margin: 0 auto;
  background-color: #c5c5c5;
}

.hojokin_results_item > div > div > p {
  width: 270px;
  padding: 10px 15px 0;
  text-align: left;
  font-size: 12px;
  line-height: 25px;
  color: #373737;
}

.hojokin_results_btn {
  text-decoration: none;
  color: #222222;
  width: 250px;
  height: 50px;
  line-height: 50px;
  color: #222222;
  border: 1px solid #222222;
  font-size: 15px;
  border-radius: 100px;
  margin: 0px auto 0;
}

.hojokin_results_btn:hover {
  color: white;
  border: 1px solid #222222;
  background-color: #222222;
}



