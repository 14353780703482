.simulator_result_header {
  width: 100vw;
  height: 200px;
  background-color: #abb6ec;
  text-align: center;
  background-image: url(./../.././../../../image/simulator/result_header.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.simulator_result_header > div {
  display: flex;
  padding-top: 70px;
  width: 450px;
  margin: 0 auto;
}

@media (max-width: 499px) and (min-width: 0px) {
  .simulator_result_header > div {
    width: 100vw;
  }
}

.simulator_result_header > div > img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 3px solid white;
  margin-right: 20px;
}

.simulator_result_header > div > h4 {
  width: 100vw;
  font-weight: bold;
  color: white;
  font-size: 25px;
  line-height: 80px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 499px) and (min-width: 0px) {
  .simulator_result_header > div > h4 {
    width: 100vw;
  }
}


