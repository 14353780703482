/* ナビゲーション全体 */

.simulator_nav {
  width: 1100px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 10px calc((100vw - 1100px) / 2);
}

@media (max-width: 1100px) and (min-width: 0px) {
  .simulator_nav {
    width: 90vw;
    padding: 10px calc((100vw - 90vw) / 2);
  }
}

/* ナビゲーション左 */

.simulator_nav_title {
  display: flex;
  justify-content: space-between;
}

.simulator_nav_title > img {
  width: 35px;
  height: 35px;
  object-fit: contain;
  margin: 12.5px 15px 12.5px 0;
}

.simulator_nav_title > div {
  margin: 10px 0;
}

.simulator_nav_title > div > h1 {
  font-size: 15px;
  color: #222222;
  line-height: 20px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 500px) and (min-width: 0) {
  .simulator_nav_title > div > h1 {
    font-size: 13px;
  }
}

.simulator_nav_title > div > h2 {
  font-size: 10px;
  color: #575757;
  line-height: 15px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

/* ナビゲーション右 */

.simulator_nav_link {
  display: flex;
  justify-content: space-between;
  width: 550px;
}

@media (max-width: 1100px) and (min-width: 700px) {
  .simulator_nav_link {
    width: 350px;
  }
}

@media (max-width: 700px) and (min-width: 500px) {
  .simulator_nav_link {
    width: 200px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .simulator_nav_link {
    width: 110px;
  }
}

.simulator_nav_link > a > p {
  font-size: 15px;
  line-height: 60px;
  color: #222222;
  font-weight: bold;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

@media (max-width: 1100px) and (min-width: 700px) {
  .simulator_nav_link > a > p:nth-child(1) {
    display: none;
  }
}

@media (max-width: 700px) and (min-width: 500px) {
  .simulator_nav_link > a > p:nth-child(1),
  .simulator_nav_link > a > p:nth-child(2) {
    display: none;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .simulator_nav_link > a > p:nth-child(1),
  .simulator_nav_link > a > p:nth-child(2),
  .simulator_nav_link > a > p:nth-child(3) {
    display: none;
  }
}

.simulator_nav_link > a > button {
  font-size: 13px;
  line-height: 40px;
  color: #222222;
  font-weight: bold;
  background-color: #222222;
  border: 0;
  color: white;
  margin: 10px 0;
  padding: 0 20px;
  border-radius: 3px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}


