
.task_setting {
  top: 0;
  z-index: 100;
  width: 1200px;
  margin: 0 calc(7.5vw - 35px) 50px calc(7.5vw + 35px);
}

.task_setting_item_top {
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.task_setting_item_top > div > p {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 15px;
}

.task_setting_item_top > div > input {
  width: 250px;
  height: 40px;
  margin-top: 10px;
  border-radius: 0;
  background-color: #fafafa;
  border: .5px solid #D9D9D9;
  text-indent: 1em;
}

.task_setting_item_top > div > select {
  width: 250px;
  height: 40px;
  margin-top: 10px;
  border-radius: 0;
  background-color: #fafafa;
  border: .5px solid #D9D9D9;
  text-indent: 1em;
}

.task_setting_item {
  width: 1200px;
  margin-top: 50px;
}

.task_setting_item > div {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.task_setting_item > div > div {
  width: 250px;
}

.task_setting_item > div > div > p {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 15px;
}

.task_setting_item > div > div > input {
  width: 250px;
  height: 40px;
  margin-top: 10px;
  border-radius: 0;
  background-color: #fafafa;
  border: .5px solid #D9D9D9;
  text-indent: 1em;
}

.task_setting_item > div > div > select {
  width: 250px;
  height: 40px;
  margin-top: 10px;
  border-radius: 0;
  background-color: #fafafa;
  border: .5px solid #D9D9D9;
  text-indent: 1em;
}

.task_setting_save {
  width: 60px;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  font-weight: bold;
  background-color: #222222;
  margin: 0 calc(7.5vw - 35px) 50px calc(7.5vw + 35px + 1140px);
  text-align: center;
  color: white;
}


.task_setting_delete {
  margin: 0 auto;
  width: 150px;
  height: 40px;
  line-height: 40px;
  color: #222222;
  text-align: center;
  border: 1px solid #222222;
  font-weight: bold;
}




