.help_header {
  width: 80%;
  padding: 7vh 10%;
  background-color: #F5F2EA;
  justify-content: space-between;
}

.help_header > h1 {
  text-align: center;
  padding-top: 80px;
  font-size: 36px;
  -webkit-text-stroke: .3px #222222;
}

@media (max-width: 900px) and (min-width: 500px) {
  .help_header > h1 {
    font-size: 32px;
  }
}

.header_1 {
  color: #ff7847;
}

.header_2 {
  color: #ffd04f;
}

.header_3 {
  color: #68ceeb;
}

.header_4 {
  color: #76c8c1;
}

.help_header > p {
  padding-top: 30px;
  font-size: 15px;
  width: 700px;
  margin: 0 auto;
  line-height: 30px;
  color: #444444;
}

@media (max-width: 900px) and (min-width: 500px) {
  .help_header > p {
    width: 100%;
    font-size: 13px;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_header > p {
    width: 100%;
    font-size: 13px;
  }
}

.help_header > h6 {
  width: 250px;
  height: 50px;
  line-height: 50px;
  background-color: white;
  margin: 80px auto 0;
  background-color: #222222;
  border-radius: 100px;
  font-weight: 300;
  font-size: 18px;
  font-weight: bold;
  color: white;
  text-align: center;
}

@media (max-width: 900px) and (min-width: 500px) {
  .help_header > h6 {
    margin: 50px auto 0;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_header > h6 {
    margin: 50px auto 0;
  }
}

.help_header > div {
  display: flex;
  justify-content: space-between;
  margin: 60px auto 0;
  text-align: center;
}

@media (max-width: 900px) and (min-width: 700px) {
  .help_header > div {
    flex-wrap: wrap;
    width: 550px;
  }
}

@media (max-width: 700px) and (min-width: 500px) {
  .help_header > div {
    flex-wrap: wrap;
    width: 400px;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_header > div {
    flex-wrap: wrap;
    width: 100%;
  }
}

.help_header > div > div {
  width: 250px;
  height: 250px;
  border: 1px solid #373737;
  background-color: white;
  margin-top: 20px;
}

@media (max-width: 1300px) and (min-width: 1100px) {
  .help_header > div > div {
    width: 215px;
    height: 215px;
  }
}

@media (max-width: 1100px) and (min-width: 900px) {
  .help_header > div > div {
    width: 175px;
    height: 175px;
  }
}

@media (max-width: 900px) and (min-width: 700px) {
  .help_header > div > div {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 700px) and (min-width: 500px) {
  .help_header > div > div {
    width: 180px;
    height: 180px;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_header > div > div {
    width: 38vw;
    height: 38vw;
  }
}

.help_header > div > div > img {
  width: 150px;
  height: 150px;
  margin: 30px auto 0;
}

@media (max-width: 1300px) and (min-width: 1100px) {
  .help_header > div > div > img {
    margin: 15px auto;
  }
}

@media (max-width: 1100px) and (min-width: 900px) {
  .help_header > div > div > img {
    width: 140px;
    height: 140px;
    margin-top: 0px;
  }
}

@media (max-width: 700px) and (min-width: 500px) {
  .help_header > div > div > img {
    width: 130px;
    height: 130px;
    margin-top: 10px;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_header > div > div > img {
    width: 100px;
    height: 100px;
    margin-top: 10px;
  }
}

.help_header > div > div > h5 {
  font-size: 15px;
  margin-top: 10px;
  color: #373737;
}

@media (max-width: 1300px) and (min-width: 500px) {
  .help_header > div > div > h5 {
    margin-top: 0px;
  }
}

@media (max-width: 499px) and (min-width: 0) {
  .help_header > div > div > h5 {
    margin-top: 5px;
  }
}


