.photo_blog {
  margin: 100px 15vw 30px;
  width: 70vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.photo_blog > div {
  margin-bottom: 50px;
}

.photo_blog > div > img {
  width: 280px;
  height: 150px;
  border-radius: 5px 5px 0 0;
  object-fit: cover;
}

.photo_blog > div > div {
  width: 270px;
  margin: 5px 5px 0;
  font-size: 16px;
  color: #444444;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.photo_blog_instagram {
  width: 25px;
  height: 25px;
  color: #999999;
}

.photo_blog > div > p {
  width: 270px;
  margin: 5px 5px 0;
  text-align: left;
  font-size: 15px;
  color: #464646;
  margin-top: 7px;
}


@media (max-width: 1400px) and (min-width: 1200px) {
  .photo_blog {
    margin: 100px 10vw 30px;
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

@media (max-width: 1200px) and (min-width: 450px) {
  .photo_blog {
    margin: 100px 5vw 30px;
    width: 90vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

