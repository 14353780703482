.simulator_caution_background {
  background-color: #F8F6F7;
  padding: 50px 0 100px;
}

.simulator_caution_contact {
  width: 800px;
  text-align: center;
  margin: 0 auto;
  border: 1.5px solid #EB6231;
  border-radius: 3px;
  padding: 40px 0;
  background-color: white;
}

@media (max-width: 700px) and (min-width: 0px) {
  .simulator_caution_contact {
    width: 90vw;
  }
}

.simulator_caution_contact > h4 {
  font-size: 23px;
  color: #EB6231;
  margin-bottom: 20px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
}

.simulator_caution_contact > ul > li {
  width: 70%;
  text-align: left;
  font-size: 15px;
  color: #373737;
  margin-bottom: 30px;
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  margin: 0 15%;
  line-height: 30px;
}

@media (max-width: 700px) and (min-width: 0px) {
  .simulator_caution_contact > ul > li > br {
    display: none;
  }
}
