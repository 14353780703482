.photo_display {
  display: flex;
  width: 50vw;
  padding: 50px 25vw 60px;
  background-color: #FF8D89;
  margin: 100px 0;
}

.photo_display_text_title {
  width: 200px;
  line-height: 40px;
  background-color: white;
  color: #FF8D89;
  font-weight: bold;
  border-radius: 100px;
  margin: 150px 0 0px;
  text-align: center;
}

.photo_display_text > div {
  width: 380px;
  margin-top: 20px;
  border-radius: 5px;
  display: flex;
}

.photo_display_text > div > div {
  width: 55px;
  height: 55px;
  border-radius: 5px;
}

.photo_display_text_icon {
  width: 30px;
  height: 30px;
  margin: 12.5px;
  color: #FF8D89;
}

.photo_display_text > div > h4 {
  min-width: 250px;
  color: white;
  font-size: 18px;
  margin-left: 20px;
  text-align: left;
  line-height: 55px;
  border-bottom: 1px solid white;
}

.slider_box {
  position: relative;
  width: 300px;
  height: 600px;
  margin: 0 auto;
  border-radius: 40px;
}

.slider_cover {
  position: absolute;
  width: 300px;
  left: 0;
  z-index: 2;
}

.slider {
  position: absolute;
  width: 300px;
  margin: 0 auto;
  border-radius: 40px;
}

.slider_image {
  width: calc(300px - 4px);
  margin: 0 2px;
  border-radius: 40px;
}


@media (max-width: 1400px) and (min-width: 1200px) {
  .photo_display {
    display: flex;
    width: 60vw;
    padding: 50px 20vw;
    background-color: #FF8D89;
    margin: 100px 0;
  }
}

@media (max-width: 1200px) and (min-width: 800px) {
  .photo_display {
    display: flex;
    width: 80vw;
    padding: 50px 10vw;
    background-color: #FF8D89;
    margin: 100px 0;
  }
}

@media (max-width: 800px) and (min-width: 450px) {
  .photo_display {
    display: block;
    width: 70vw;
    padding: 50px 15vw;
    background-color: #FF8D89;
    margin: 100px 0;
  }

  .photo_display_text > div {
    width: 80vw;
    margin-top: 20px;
    border-radius: 5px;
    display: flex;
  }

  .photo_display_text_title {
    width: 200px;
    line-height: 40px;
    background-color: white;
    color: #FF8D89;
    font-weight: bold;
    border-radius: 100px;
    margin: 50px 0 0px;
  }

  .slider_box {
    position: relative;
    width: 300px;
    height: 600px;
    margin: 50px auto 0;
    border-radius: 40px;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .photo_display {
    display: flex;
    width: 90vw;
    padding: 50px 5vw;
    background-color: #FF8D89;
    margin: 100px 0;
  }

  .photo_display {
    display: block;
    width: 90vw;
    padding: 50px 5vw;
    background-color: #FF8D89;
    margin: 100px 0;
  }

  .photo_display_text_title {
    width: 200px;
    line-height: 40px;
    background-color: white;
    color: #FF8D89;
    font-weight: bold;
    border-radius: 100px;
    margin: 50px 0 0px;
  }

  .photo_display_text > div {
    width: 90vw;
    margin-top: 20px;
    border-radius: 5px;
    display: flex;
  }

  .slider_box {
    position: relative;
    width: 300px;
    height: 600px;
    margin: 50px auto 0;
    border-radius: 40px;
  }
}













