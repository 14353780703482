

/* bid_list_search_terms */

.bid_list_search_terms {
  width: 1000px;
  margin: 0px calc((100vw - 1000px) / 2);
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media (max-width: 1000px) and (min-width: 801px) {
  .bid_list_search_terms {
    width: 800px;
    margin: 0px calc((100vw - 800px) / 2) 50px;
  }
}

@media (max-width: 800px) and (min-width: 601px) {
  .bid_list_search_terms {
    width: 600px;
    margin: 0px calc((100vw - 600px) / 2) 50px;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .bid_list_search_terms {
    width: 90%;
    margin: 0px 5% 50px;
  }
}

.bid_list_search_terms > h4 {
  font-size: 15px;
  color: #222222;
}

.bid_list_search_terms > h5 {
  font-size: 15px;
  color: #222222;
}

/* bid_case */

.bid_list_case {
  width: 950px;
  margin: 0px calc((100vw - 1000px) / 2);
  padding: 25px 25px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

@media (max-width: 1000px) and (min-width: 801px) {
  .bid_list_case {
    width: 750px;
    margin: 0px calc((100vw - 800px) / 2) 50px;
  }
}

@media (max-width: 800px) and (min-width: 601px) {
  .bid_list_case {
    width: 550px;
    margin: 0px calc((100vw - 600px) / 2) 50px;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .bid_list_case {
    width: calc(90% - 50px);
    margin: 0px 5% 50px;
  }
}

.bid_list_case > img {
  width: 60px;
  height: 60px;
  border-radius: 300px;
  padding: 30px;
  border: 1px solid #D70A0A;
}

@media (max-width: 600px) and (min-width: 0px) {
  .bid_list_case > img {
    display: none;
  }
}

.bid_list_case > div {
  width: 80%;
}

@media (max-width: 800px) and (min-width: 601px) {
  .bid_list_case > div {
    width: calc(100% - 200px);
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .bid_list_case > div {
    width: auto;
  }
}

.bid_list_case_date {
  font-size: 13px;
  color: #222222;
  margin: 10px 0 10px;
}

.bid_list_case > div > h3 {
  font-size: 20px;
  color: #222222;
  margin: 0 0 10px;
  line-height: 40px;
}

.bid_list_case_desc {
  font-size: 15px;
  color: #444444;
  line-height: 27px;
  margin: 0 0 15px;
}

.bid_list_case > div > div {
  display: flex;
}

.bid_list_case > div > div > p {
  font-size: 13px;
  color: #222222;
  margin-right: 20px;
}

.bid_list_case_border {
  height: 3px;
  background-color: #222222;
  width: calc(70% - 55px);
  margin: 0px 15%;
}

.bid_list_case > h4 {
  height: 100%;
  line-height: 120px;
  color: #222222;
}

/* bid_list_background */

.bid_list_background {
  margin: auto 0;
  padding: 100px 0;
  background-color: #FAFAFA;
}

/* bid_list_number */

.bid_list_number {
  width: 260px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.bid_list_number > div {
  width: 40px;
  height: 40px;
  line-height: 20px;
  text-align: center;
  line-height: 40px;
  background-color: white;
  border: 1px solid #D70A0A;
  font-weight: bold;
  color: #D70A0A;
  border-radius: 2px;
}
