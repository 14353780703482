.AdBusiness {
  position: fixed;
  background-color: white;
  height: 40px;
  width: 1100px;
  padding: 22px calc((100vw - 1100px) / 2) 18px;
  display: flex;
  justify-content: space-between;
  color: #222222;
  z-index: 100;
  border-bottom: 1px solid #222222;
  background-color: white;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .AdBusiness {
    width: 950px;
    padding: 20px calc((100vw - 950px) / 2);
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness {
    width: 750px;
    padding: 20px calc((100vw - 750px) / 2);
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .AdBusiness {
    width: 550px;
    padding: 20px calc((100vw - 550px) / 2);
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .AdBusiness {
    width: 80vw;
    padding: 20px 10vw;
  }
}

.AdBusiness_icon {
  display: flex;
}

.AdBusiness_icon > img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.AdBusiness_icon > h4 {
  line-height: 40px;
  font-size: 15px;
  padding-left: 20px;
  font-weight: 500;
}

.AdBusiness_link {
  width: 300px;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
}

.AdBusiness_link > h4 {
  line-height: 40px;
  font-size: 15px;
  font-weight: 500;
}

@media (max-width: 600px) and (min-width: 0px) {
  .AdBusiness_link {
    display: none;
  }
}


.contact_form_bar {
  position: fixed;
  width: 100vw;
  bottom: 0;
  padding: 30px 0;
  background-color: green;
  z-index: 100;
  text-align: center;
}

