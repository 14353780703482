.help_result_contact {
  width: 80vw;
  height: 350px;
  background-color: #F5F2EA;
  margin: 200px 0 100px;
  padding: 0 10vw;
  border-top: 1px solid #222222;
  border-bottom: 1px solid #222222;
  text-align: center;
}

.help_result_contact > h4 {
  color: #222222;
  font-size: 25px;
  margin-top: 100px;
}

.help_result_contact > a > div {
  color: #222222;
  font-size: 20px;
  background-color: white;
  width: 200px;
  line-height: 50px;
  border: 1px solid #222222;
  margin: 50px auto;
}