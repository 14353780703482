.hojokin_point_items {
  width: 100vw;
  background-color: #fafaff;
  padding: 200px 0 100px;
}

.hojokin_point_items > h5 {
  font-size: 30px;
  color: #19347A;
  text-align: center;
}

.hojokin_point_items > div {
  width: 1000px;
  background-color: white;
  border: 2px solid #19347A;
  margin: 150px auto;
  border-radius: 5px;
  padding: 0 0 50px;
}

@media (max-width: 1000px) and (min-width: 500px) {
  .hojokin_point_items > div {
    width: 500px;
    margin: 150px auto;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .hojokin_point_items > div {
    width: 350px;
    margin: 150px auto;
  }
}

.hojokin_point_items > div > h4 {
  font-size: 30px;
  font-weight: 500;
  color: #19347A;
  margin: 50px auto 0;
  text-align: center;
}

@media (max-width: 1000px) and (min-width: 500px) {
  .hojokin_point_items > div > h4 {
    font-size: 20px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .hojokin_point_items > div > h4 {
    font-size: 16px;
  }
}

.hojokin_point_items > div > hr {
  border: none;
  width: 800px;
  height: 2px;
  background-color: #19347A;
  margin: 25px auto;
}

@media (max-width: 1000px) and (min-width: 500px) {
  .hojokin_point_items > div > hr {
    width: 400px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .hojokin_point_items > div > hr {
    width: 250px;
  }
}

.hojokin_point_items > div > div {
  width: 800px;
  margin: 40px auto 0;
  display: flex;
}

@media (max-width: 1000px) and (min-width: 0px) {
  .hojokin_point_items > div > div {
    display: block;
    width: 350px;
    margin: 0 0 0;
  }
}

.hojokin_point_items > div > div > img {
  width: 400px;
  height: 300px;
  object-fit: contain;
}

@media (max-width: 1000px) and (min-width: 500px) {
  .hojokin_point_items > div > div > img {
    width: 400px;
    margin: 20px 50px 0;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .hojokin_point_items > div > div > img {
    width: 300px;
    margin: 0 25px;
  }
}

.hojokin_point_items > div > div > div {
  width: 400px;
}

@media (max-width: 1000px) and (min-width: 500px) {
  .hojokin_point_items > div > div > div {
    width: 400px;
    margin: 30px 50px 0;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .hojokin_point_items > div > div > div {
    width: 300px;
    margin: 0 25px;
  }
}

.hojokin_point_items > div > div > div > h4 {
  text-align: left;
  font-size: 18px;
  line-height: 40px;
  font-weight: 500;
}

.hojokin_point_items > div > div > div > p {
  text-align: left;
  font-size: 13px;
  line-height: 30px;
  font-weight: 500;
  color: #373737;
  margin-top: 15px;
}

.hojokin_point_items > div > div > div > div {
  width: 400px;
  flex-wrap: wrap;
  display: flex;
  margin-top: 20px;
}

.hojokin_point_items > div > div > div > div > p {
  padding: 10px 20px;
  border: 1px solid #19347A;
  margin: 20px 20px 0 0;
  font-size: 13px;
  color: #19347A;
  font-weight: 600;
}

@media (max-width: 10000px) and (min-width: 1000px) {
  .image_mini {
    display: none;
  }
}

@media (max-width: 1000px) and (min-width: 0px) {
  .image_big {
    display: none;
  }
}

