
.hojokin_flow {
  width: 800px;
  margin: 150px auto 150px;
}

@media (max-width: 800px) and (min-width: 0px) {
  .hojokin_flow {
    width: 500px;
    margin: 150px auto 0;
  }
}

@media (max-width: 800px) and (min-width: 0px) {
  .hojokin_flow {
    width: 350px;
    margin: 150px auto 0;
  }
}

.hojokin_flow > h4 {
  font-size: 20px;
  color: #19347A;
  text-align: center;
  margin-bottom: 100px;
}

.hojokin_flow > div {
  font-size: 18px;
  color: #19347A;
  text-align: center;
  width: 680px;
  border: 1px solid #19347A;
  border-radius: 5px;
  margin: 0 0 50px;
  padding: 50px 60px;
}

@media (max-width: 800px) and (min-width: 500px) {
  .hojokin_flow > div {
    display: block;
    width: 420px;
    padding: 50px 40px;
  }
}

@media (max-width: 500px) and (min-width: 0px) {
  .hojokin_flow > div {
    display: block;
    width: 310px;
    padding: 50px 20px;
  }
}

.hojokin_flow > div > h5 {
  width: 200px;
  font-size: 18px;
  color: #19347A;
  text-align: left;
  font-weight: 600;
}

.hojokin_flow > div > h6 {
  font-size: 13px;
  color: #222222;
  text-align: left;
  font-weight: 600;
  margin-top: 20px;
}

@media (max-width: 800px) and (min-width: 0px) {
  .hojokin_flow > div > h6 {
    margin-top: 15px;
  }
}