.subsidy_results_nav {
  position: fixed;
  width: 1000px;
  padding: 0 calc((100vw - 1000px) / 2);
  height: 80px;
  border-bottom: .5px solid #EBEBEB;
  display: flex;
  justify-content: space-between;
  z-index: 10000;
  background-color: white;
}

.subsidy_results_nav_icon {
  display: flex;
  height: 50px;
  margin: 15px 0;
}

.subsidy_results_nav_icon > img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.subsidy_results_nav_icon > div {
  margin: calc((50px - 35px) / 2) 0;
}

.subsidy_results_nav_icon > div > h1 {
  height: 20px;
  line-height: 20px;
  font-size: 13px;
  color: #222222;
  margin-left: 10px;
}

.subsidy_results_nav_icon > div > h2 {
  height: 15px;
  line-height: 15px;
  font-size: 10px;
  color: #1F2C5B;
  margin-left: 10px;
}

.subsidy_results_nav_link {
  display: flex;
  justify-content: space-between;
  width: 550px;
  height: 50px;
  margin: 15px 0;
}

.subsidy_results_nav_link > a > p {
  font-size: 13px;
  line-height: 50px;
  font-weight: bold;
  color: #222222;
}

.subsidy_results_nav_link > a > p > span {
  font-size: 8px;
  line-height: 50px;
  color: #222222;
}

.subsidy_results_nav_link > a > button {
  border: none;
  width: 150px;
  text-align: center;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  margin: 5px 0;
  background-color: #1F2C5B;
  border-radius: 5px;
  color: white;
}

/* 1000px */

@media (max-width: 1000px) and (min-width: 800px) {
  .subsidy_results_nav {
    width: 800px;
    padding: 0 calc((100vw - 800px) / 2);
  }

  .subsidy_results_nav_link {
    width: 550px;
  }
}

/* 800px */

@media (max-width: 800px) and (min-width: 600px) {
  .subsidy_results_nav {
    width: 600px;
    padding: 0 calc((100vw - 600px) / 2);
  }

  .subsidy_results_nav_link {
    width: 150px;
  }

  .subsidy_results_nav_link > a:nth-child(3),
  .subsidy_results_nav_link > a:nth-child(1),
  .subsidy_results_nav_link > a:nth-child(2) {
    display: none;
  }
}

/* 600px */

@media (max-width: 600px) and (min-width: 0px) {
  .subsidy_results_nav {
    width: 90%;
    padding: 0 5%;
  }

  .subsidy_results_nav_link {
    width: 130px;
  }

  .subsidy_results_nav_link > a:nth-child(3),
  .subsidy_results_nav_link > a:nth-child(1),
  .subsidy_results_nav_link > a:nth-child(2) {
    display: none;
  }

  .subsidy_results_nav_link > a > button {
    width: 130px;
  }
}



