.photo_solve_title {
  width: 100vw;
  font-size: 35px;
  text-align: center;
  color: #444444;
  margin: 150px 0 80px;
}

/* ==================== */

.photo_solve_content {
  width: 80vw;
  margin: 50px 10vw;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
}

.photo_solve_content > div {
  width: 300px;
}

.photo_solve_content_problem {
  width: 300px;
  border-radius: 5px;
  padding: 40px 0;
  line-height: 20px;
  background-color: #FFEBEB;
  color: #222222;
}

.photo_solve_content_arrow_icons {
  width: 30px;
  height: 30px;
  color: #FF8D89;
  margin: 10px 125px;
}

.photo_solve_content_solve {
  width: 300px;
  border-radius: 5px;
  padding: 40px 0;
  line-height: 20px;
  color: #222222;
  border: 2px solid #FF8D89;
  background-color: white;
}

@media (max-width: 1300px) and (min-width: 1100px) {
  .photo_solve_content {
    width: 90vw;
    margin: 50px 5vw;
    display: flex;
    justify-content: space-evenly;
  }
}

@media (max-width: 1100px) and (min-width: 1000px) {
  .photo_solve_content {
    width: 95vw;
    margin: 50px 2.5vw;
    display: flex;
    justify-content: space-evenly;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .photo_solve_content {
    width: 100vw;
    margin: 50px 0;
    display: flex;
    justify-content: space-evenly;
  }

  .photo_solve_content > div {
    width: 250px;
  }
  
  .photo_solve_content_problem {
    width: 250px;
  }
  
  .photo_solve_content_arrow_icons {
    margin: 10px 105px;
  }
  
  .photo_solve_content_solve {
    width: 250px;
  }
}

@media (max-width: 800px) and (min-width: 650px) {
  .photo_solve_content {
    width: 100vw;
    margin: 50px 0vw;
    display: flex;
    justify-content: space-evenly;
  }
  
  .photo_solve_content_none {
    display: none;
  }
}

@media (max-width: 650px) and (min-width: 400px) {
  .photo_solve_content {
    width: 100vw;
    margin: 50px 0vw;
    display: flex;
    justify-content: space-evenly;
  }
  
  .photo_solve_content_none {
    display: none;
  }

  .photo_solve_content {
    width: 100vw;
    margin: 50px 0vw;
    display: flex;
    justify-content: space-evenly;
  }

  .photo_solve_content > div {
    width: 230px;
  }
  
  .photo_solve_content_problem {
    width: 230px;
  }
  
  .photo_solve_content_arrow_icons {
    margin: 10px 100px;
  }
  
  .photo_solve_content_solve {
    width: 230px;
  }
}

@media (max-width: 450px) {
  .photo_solve_content_none {
    display: none;
  }

  .photo_solve_content {
    width: 100vw;
    margin: 50px 0vw;
    display: flex;
    justify-content: space-evenly;
  }
  
  .photo_solve_content_none {
    display: none;
  }

  .photo_solve_content {
    width: 100vw;
    margin: 50px 0vw;
    display: flex;
    justify-content: space-evenly;
  }

  .photo_solve_content > div {
    width: 180px;
  }
  
  .photo_solve_content_problem {
    width: 180px;
    font-size: 15px;
    padding: 30px 0;
  }
  
  .photo_solve_content_arrow_icons {
    margin: 10px 75px;
  }
  
  .photo_solve_content_solve {
    width: 180px;
    font-size: 15px;
    padding: 30px 0;
  }
}





