.bid_slider_box {
  position: relative;
  width: 100vw;
  height: 500px;
  border-radius: 40px;
}

.bid_slider_2000 {
  position: absolute;
  width: 100%;
  height: 500px;
  border-radius: 0px;
}

.bid_slider_image {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.bid_slider > ul {
  margin: -50px 0 0;
}

.slide-animation{
  animation: fadezoom 50s 0s forwards;
}
@keyframes fadezoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

/* 2000 */

@media (max-width: 10000px) and (min-width: 1201px) {
  .bid_slider_1200 {
    display: none;
  }
  
  .bid_slider_700 {
    display: none;
  }
}

/* 1200 */

@media (max-width: 1200px) and (min-width: 701px) {
  .bid_slider_2000 {
    display: none;
  }

  .bid_slider_700 {
    display: none;
  }
}

/* 700 */

@media (max-width: 700px) and (min-width: 0px) {
  .bid_slider_2000 {
    display: none;
  }

  .bid_slider_1200 {
    display: none;
  }
  
}













