.test_homeimage_recruit {
  width: 1000px;
  padding: 100px calc((100% - 1000px) / 2) 0px;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .test_homeimage_recruit {
    width: 960px;
    padding: 0px calc((100% - 960px) / 2) 0;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .test_homeimage_recruit {
    width: 740px;
    padding: 30px calc((100vw - 740px) / 2) 0;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .test_homeimage_recruit {
    width: 540px;
    padding: 30px calc((100vw - 540px) / 2) 0;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .test_homeimage_recruit {
    width: 90vw;
    padding: 30px 5vw 50px;
  }
}

.test_homeimage_recruit_title {
  width: 80vw;
  margin-bottom: 80px;
}

.test_homeimage_recruit_title > p {
  font-size: 15px;
  text-align: left;
  color: #222222;
  line-height: 10px;
}

.test_homeimage_recruit_title > h4 {
  font-size: 70px;
  text-align: left;
  color: #222222;
  line-height: 65px;
}

.test_homeimage_recruit_items {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

@media (max-width: 800px) and (min-width: 0px) {
  .test_homeimage_recruit_items {
    display: block;
  }
}

.test_homeimage_recruit_items_image {
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #222222;
  z-index: 2;
  margin: 0 10px;
}

@media (max-width: 800px) and (min-width: 00px) {
  .test_homeimage_recruit_items_image {
    width: 240px;
    height: 240px;
    margin: 30px calc((100% - 240px) / 2);
  }
}

.test_homeimage_recruit_items_text {
  width: 750px;
  border-radius: 10px;
  z-index: 1;
  border: 1px solid #222222;
}

@media (max-width: 1000px) and (min-width: 800px) {
  .test_homeimage_recruit_items_text {
    width: 500px;
    height: 200px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .test_homeimage_recruit_items_text {
    width: 540px;
    height: 200px;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .test_homeimage_recruit_items_text {
    width: 100%;
    height: 200px;
  }
}

.test_homeimage_recruit_items_text > h4 {
  font-size: 15px;
  color: #111111;
  line-height: 27px;
  padding: 30px 30px 0;
}

.test_homeimage_recruit_items_text > p {
  font-size: 13px;
  color: #111111;
  line-height: 30px;
  padding: 20px 30px 0;
}

.test_homeimage_recruit_items_text > a > button {
  width: 120px;
  height: 38px;
  border: 1px solid #111111;
  color: #111111;
  float: right;
  margin: 5px 30px 0 0;
  color: white;
}

