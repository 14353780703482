.photolist_header {
  background-color: #fafafa;
}

/* scout_address */

.scout_register {
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
}

.scout_register_background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: .4;
}

.scout_register_box {
  position: absolute;
  width: 30vw;
  height: 50vh;
  background-color: white;
  z-index: 1;
  margin: 25vh 35vw;
  border-radius: 10px;
}

.scout_register_box > h4 {
  width: 30vw;
  margin: 30px 0 20px;
  font-size: 25px;
  color: #44698a;
}

.scout_register_box > p {
  margin: 0 30px;
  font-size: 15px;
  color: #333333;
}

.scout_register_name {
  width: 25vw;
  margin: 20px 2.5vw 0;
  text-align: left;
}

.scout_register_input {
  width: 25vw;
  margin: 0 2.5vw;
  height: 40px;
  border: none;
  border-bottom: 1px solid #333333;
}

.scout_register_box > div {
  display: flex;
  background-color: #373737;
  border-radius: 3px;
  margin: 20px 7.3vw 0;
}

.google_account {
  width: 30px;
  height: 30px;
  padding: 10px;
  color: white;
}

.scout_register_box > div > h4 {
  line-height: 47px;
  color: white;
  margin-left: 10px;
  padding-right: 10px;
}

/* scout_info */

.scout_info {
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
}

.scout_info_box {
  position: absolute;
  width: 43vw;
  height: 60vh;
  background-color: white;
  z-index: 1;
  margin: 17.5vh 26.5vw;
  border-radius: 10px;
  display: flex;
  padding: 4vh 2.5vw;
  justify-content: space-between;
}

.scout_info_box_left {
  width: 280px;
}

.scout_info_box_left > img {
  width: 280px;
  height: 280px;
  object-fit: cover;
  border-radius: 5px;
}

.scout_info_box_left > div {
  margin-top: 25px;
  width: 280px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  justify-content: space-between;
}

.scout_info_box_left > div > div {
  width: 230px;
  display: flex;
  height: 30px;
  margin: 0 10px;
  border-bottom: 1px solid rgb(192, 192, 192);
  justify-content: space-between;
  margin-bottom: 5px;
  padding-top: 4px;
}

.scout_info_box_left > div > div > h4 {
  font-size: 13px;
  font-weight: 300;
  line-height: 30px;
}

.scout_info_box_left > div > div > h5 {
  width: 150px;
  font-size: 13px;
  text-align: left;
  font-weight: 300;
  line-height: 30px;
}

.scout_info_box_left > div > div > h5 > span {
  text-decoration: underline;
  text-decoration-color: rgb(215, 215, 215);
}

.scout_info_box_line {
  width: 1px;
  height: 60vh;
  background-color: rgb(192, 192, 192);
}

.scout_info_box_right {
  width: 280px;
}

.scout_info_box_right > h4 {
  text-align: left;
  font-size: 15px;
  margin-bottom: 20px;
}

.scout_info_box_right > div {
  margin-top: 10px;
  width: 280px;
  display: flex;
  justify-content: space-between;
}

.scout_info_box_right > div > img {
  width: 135px;
  height: 135px;
  object-fit: cover;
  border-radius: 5px;
}

.scout_info_box_right > h4 {
  text-align: left;
}

/* scout_header */

.scout_header {
  position: relative;
  width: 100vw;
  height: 550px;
  /* min-width: 1300px; */
  overflow: hidden;
}

.scout_header > img {
  position: absolute;
  width: 100vw;
  min-width: 1300px;
  height: 550px;
  object-fit: cover;
  left: 0;
}

.scout_header_pc {
  position: absolute;
  color: white;
  font-size: 40px;
  width: 80vw;
  min-width: 1000px;
  margin: 100px 10vw;
  text-align: center;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.scout_header_title > h4 {
  color: white;
  font-size: 40px;
  text-align: left;
  margin-top: 150px;
}

.scout_header_title > p {
  color: white;
  font-size: 15px;
  width: 30vw;
  min-width: 400px;
  text-align: left;
  margin-top: 30px;
}

.scout_header_img {
  width: 30vw;
  min-width: 430px;
  display: flex;
  justify-content: space-between;
}

.app_img_1 {
  width: 200px;
}

.app_img_2 {
  width: 200px;
}

/* scout_box */

.scout_box {
  width: 80vw;
  padding: 100px 10vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: #fafafa;
}

.scout_box_items {
  width:  170px;
  height: 170px;
  margin: 20px;
}

.scout_box_items > img {
  width:  170px;
  height: 170px;
  object-fit: cover;
  border-radius: 5px;
}

@media (max-width: 1300px) and (min-width: 450px) {
  .scout_header_phone {
    display: none;
  }

  .scout_box {
    width: 90vw;
    padding: 100px 5vw;
  }
}

@media (max-width: 450px)  {
  .scout_info {
    display: none;
  }

  .scout_header_pc {
    display: none;
  }

  .scout_header_phone {
    position: absolute;
    display: block;
    width: 90vw;
    margin: 110px 5vw 0;
  }

  .scout_header_phone > h4 {
    font-size: 20px;
    width: 90vw;
    text-align: center;
    color: white;
    margin-bottom: 20px;
  }
  
  .scout_header_phone_img {
    display: flex;
    justify-content: space-between;
  }

  .scout_header_phone_img > img {
    width: 42vw;
  }

  .scout_header_phone_btn {
    width: 50vw;
    height: 40px;
    background-color: #333333;
    border-radius: 5px;
    display: flex;
    margin: 10px 20vw;
    
  }

  .scout_header_phone_btn > h4 {
    font-size: 18px;
    line-height: 40px;
    color: white;
    width: 50vw;
    text-align: center;
  }

  .scout_box {
    width: 95vw;
    padding: 50px 2.5vw;
  }

  .scout_box_items {
    width:  42vw;
    height: 42vw;
    margin: 10px;
  }

  .scout_box_items > img {
    width:  42vw;
    height: 42vw;
  }
}


