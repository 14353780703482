





.help_point1 {
  width: 80%;
  padding: 10px 10% 50px;
  background-color: #fafafa;
}

.help_point1 > div {
  display: flex;
  justify-content: space-between;
}

.help_point1 > div > h3 {
  font-size: 40px;
  padding-top: 65px;
}

.help_point1 > div > h6 {
  font-size: 100px;
  color: #E5E5E5;
}

.help_point1 > h4 {
  font-size: 20px;
  text-align: left;
}

.help_point1 > p {
  font-size: 15px;
  text-align: left;
  color: #373737;
  padding-top: 10px;
}


.help_point1_bottom {
  display: flex;
  width: 80%;
  padding: 0px 10% 50px;
  background-color: #fafafa;
  justify-content: space-between;
}

.help_point1_bottom > div {
  width: 75px;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  line-height: 75px;
  font-weight: bold;
}

.help_bottom_icon {
  width: 17px;
  height: 17px;
  padding-top: 90px;
}

.help_point1_bottom_desc {
  width: 80%;
  padding: 0px 10% 0px;
  display: flex;
  background-color: #fafafa;
}

.help_point1_bottom_desc_1 {
  width: 20px;
  height: 20px;
  border: 2px solid #373737;
  background-color: #E4F0FA;
}

.help_point1_bottom_desc_2 {
  width: 20px;
  height: 20px;
  border: 2px solid #373737;
  background-color: #F7CBD3;
  margin-left: 40px;
}


.help_point2_bottom_3 {
  position: absolute;
  width: 76%;
  margin: 0 2% 0;
  display: flex;
  justify-content: space-between;
}

.help_point2_bottom_3 > div {
  width: 15%;
  margin: 35px 0;
}

.help_point2_bottom_3 > div > h4 {
  font-size: 20px;
  margin: 40px 0;
}


.help_point2_bottom_4 {
  position: absolute;
  width: 76%;
  margin: 45px 2% 0;
}

.help_point2_bottom_4 > div {
  width: 100%;
  height: 1px;
  margin-top: 70px;
  background-color: #373737;
}


.help_point4_bottom {
  width: 90%;
  padding: 0px 0 1000px 10%;
  background-color: #ffffff;
}

.help_point4_bottom > div {
  padding-bottom: 30px;
}

.help_point4_bottom > div > h5 {
  font-size: 100px;
  color: #eaeaea;
  text-align: left;
}

.help_point4_bottom > div > div {
  display: flex;
}

.help_point4_bottom > div > div > h4 {
  font-size: 40px;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-align: left;
  margin-top: 30px;
}

.help_point4_bottom > div > div > div {
  overflow-x: scroll;
  display: flex;
  padding: 30px 0 30px 50px;
}::-webkit-scrollbar{
  display: none;
}

.help_point4_bottom > div > div > div > div {
  display: flex;
}

.help_point4_bottom > div > div > div > div > div {
  width: 250px;
  height: 350px;
  margin-right: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(./../../../image/help/subsidy3_1.png);
}


.help_result_back {
  width: 100vw;
  background-color: #fafafa;
  padding-top: 50px;
}

.help_result_back > div {
  background-color: white;
}




.help_result_blog {
  background-color: white;
}


.help_footer {
  border: 1px solid #959595;
}
