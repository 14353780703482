.AdBusiness_header_bar {
  width: 1000px;
  padding: 30px calc((100vw - 1000px) / 2);
  background-color: #39302C;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) and (min-width: 0px) {
  .AdBusiness_header_bar {
    display: block;
    width: 80vw;
    padding: 30px 10vw;
  }
}

.AdBusiness_header_bar > h4 {
  text-align: left;
  line-height: 40px;
  font-size: 18px;
  color: white;
}

@media (max-width: 1200px) and (min-width: 500px) {
  .AdBusiness_header_bar > h4 {
    text-align: center;
  }
}

@media (max-width: 499px) and (min-width: 0px) {
  .AdBusiness_header_bar > h4 {
    text-align: left;
  }
}

.AdBusiness_header_bar >  a > div {
  width: 150px;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  background-color: white;
  color: #222222;
  text-align: center;
  font-weight: bold;
  border-radius: 3px;
}

.AdBusiness_header_bar >  a > div:hover {
  width: 148px;
  height: 38px;
  line-height: 38px;
  background-color: #39302C;
  border: 1px solid white;
  color: white;
}

@media (max-width: 1200px) and (min-width: 500px) {
  .AdBusiness_header_bar >  a > div {
    margin: 10px auto 0;
  }
}

@media (max-width: 499px) and (min-width: 0px) {
  .AdBusiness_header_bar >  a > div {
    width: 100%;
  }
}


