.AdBusiness_header {
  width: 1100px;
  padding: calc(75px + 80px) calc((100vw - 1100px) / 2) 75px;
  background-color: #F4F2EB;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .AdBusiness_header {
    width: 950px;
    padding: calc(75px + 80px) calc((100vw - 950px) / 2) 75px;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_header {
    width: 750px;
    padding: calc(75px + 80px) calc((100vw - 750px) / 2) 75px;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .AdBusiness_header {
    width: 550px;
    padding: calc(75px + 80px) calc((100vw - 550px) / 2) 75px;
  }
}

@media (max-width: 600px) and (min-width: 500px) {
  .AdBusiness_header {
    width: 450px;
    padding: calc(75px + 80px) calc((100vw - 450px) / 2) 75px;
  }
}

@media (max-width: 499px) and (min-width: 0px) {
  .AdBusiness_header {
    width: 350px;
    padding: calc(50px + 80px) calc((100vw - 350px) / 2) 50px;
  }
}

.AdBusiness_header_img {
  width: 250px;
  display: flex;
  justify-content: space-between;
}

.AdBusiness_header_img > img {
  height: 350px;
  object-fit: contain;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .AdBusiness_header_img_side {
    display: none;
  }

  .AdBusiness_header_img > img {
    width: calc((950px - 500px) / 2);
    height: 350px;
    object-fit: contain;
  }
}

@media (max-width: 1000px) and (min-width: 800px) {
  .AdBusiness_header_img_side {
    display: none;
  }

  .AdBusiness_header_img > img {
    width: calc((750px - 500px) / 2);
    height: 350px;
    object-fit: contain;
  }
}

@media (max-width: 800px) and (min-width: 600px) {
  .AdBusiness_header_img_side {
    display: none;
  }

  .AdBusiness_header_img > img {
    display: none;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .AdBusiness_header_img_side {
    display: none;
  }

  .AdBusiness_header_img > img {
    display: none;
  }
}

.AdBusiness_header_text {
  width: 500px;
}

@media (max-width: 600px) and (min-width: 500px) {
  .AdBusiness_header_text {
    width: 480px;
  }
}

@media (max-width: 499px) and (min-width: 0px) {
  .AdBusiness_header_text {
    width: 350px;
  }
}

.AdBusiness_header_text > h1 {
  text-align: center;
  padding-top: 0px;
  font-size: 25px;
  -webkit-text-stroke: .3px #222222;
}

@media (max-width: 500px) and (min-width: 0px) {
  .AdBusiness_header_text > h1 {
    font-size: 20px;
  }
}

.AdBusiness_header_text > h4 {
  font-size: 30px;
  color: #39302C;
  padding: 15px 0;
  text-align: center;
}

@media (max-width: 500px) and (min-width: 0px) {
  .AdBusiness_header_text > h4 {
    font-size: 25px;
  }
}

.AdBusiness_header_text > hr {
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 500px) and (min-width: 0px) {
  .AdBusiness_header_text > hr {
    width: 350px;
  }
}

.AdBusiness_header_text > div {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  width: 100%;
}

.AdBusiness_header_text > div > div {
  width: 150px;
  height: 150px;
  background-image: url(./../../../../image/adbusiness/header_award.png);
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 499px) and (min-width: 0px) {
  .AdBusiness_header_text > div > div {
    width: 110px;
    height: 110px;
  }
}

.AdBusiness_header_text > div > div > h5 {
  color: #D28741;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  padding-top: 30px;
}

@media (max-width: 499px) and (min-width: 0px) {
  .AdBusiness_header_text > div > div > h5 {
    font-size: 13px;
    padding-top: 18px;
  }
}

.AdBusiness_header_text > div > div > h4 {
  color: #D28741;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
}

@media (max-width: 499px) and (min-width: 0px) {
  .AdBusiness_header_text > div > div > h4 {
    line-height: 25px;
    font-size: 28px;
  }
}

